@use 'sass:map';

article > .search-overlay {
    position: relative;
    z-index: 1;

    display: block;

    &:after {
        @include is-absolute-fs;
        content: '';
        z-index: 100;

        border: 0.2rem solid $grey-gallery;

        pointer-events: none;
    }
}

.styleguide-navigation__section {
    .search-overlay {
        z-index: map.get($z-indexes, 'styleguide-top');
    }
}

body.is-safari-ios {
    .search-overlay {
        &__results {
            padding: 0 0 14rem;

            @include mq('medium') {
                padding: 3.2rem calc(var(--bs-gutter-x) * 4) 2rem;
            }

            @include mq('large') {
                padding: 4.5rem 0 6rem;
            }
        }

        &__no-results {
            img {
                @include object-fit(contain);
                top: calc(2rem + 8vh - 8rem);
                left: 0;
                z-index: 0;

                width: 100%;
                height: calc(100% - 2rem - 8vh);

                object-position: 50% 100%;

                @include mq('medium') {
                    top: calc(10rem + 20vh);

                    height: calc(100% - 2rem - 20vh);
                }
            }
        }
    }
}

// Default state
.search-overlay {
    $this: &;
    $left-gutter: 8rem + 36rem - 1rem;

    position: fixed;
    top: 0;
    left: 0;
    z-index: map.get($z-indexes, 'top');

    display: none;

    width: 100%;
    height: auto;

    background: $white;

    overflow: hidden;

    @include mq('medium') {
        height: 100%;

        overflow-x: hidden;
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    &.is-default {
        #{$this}__results {
            // opacity: 0;
        }
    }

    &.is-default,
    &.is-results {
        #{$this}__results-products-wrapper {
            > * {
                opacity: 0;
                transform: translateY(6rem);
            }
        }
    }

    &.is-results-in {
        height: 100%;
    }

    &.is-default-in,
    &.is-results-in {
        #{$this}__categories-content-wrapper {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.6s $ease-out-soft,
                transform 0.6s $ease-out-soft;
        }

        #{$this}__results {
            opacity: 1;

            transition: opacity 0.3s $ease-in-out-soft;
        }

        #{$this}__results-products-wrapper {
            > * {
                opacity: 1;
                transform: none;

                @for $i from 1 through 12 {
                    &:nth-child(#{$i}) {
                        transition:
                            opacity 0.6s $ease-out-soft 0.08s * $i,
                            transform 0.6s $ease-out-soft 0.08s * $i;
                    }
                }

                @include mq('medium') {
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            transition:
                                opacity 0.6s $ease-out-soft 0.08s * $i,
                                transform 0.6s $ease-out-soft 0.08s * $i;
                        }
                    }
                }
            }
        }

        #{$this}__results-more-cta-wrapper,
        #{$this}__results-query-header-count {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.6s $ease-out-soft,
                transform 0.6s $ease-out-soft;
        }

        #{$this}__suggestion-wrapper {
            opacity: 1;

            transition: opacity 0.3s $ease-in-out-soft;
        }
    }

    &.is-default-in {
        #{$this}__results {
            opacity: 1;

            transition: opacity 0.3s $ease-in-out-soft;
        }

        #{$this}__results-query-header-default {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.6s $ease-out-soft,
                transform 0.6s $ease-out-soft;
        }
    }

    &.is-results {
        #{$this}__results-more-cta-wrapper {
            display: block;

            @include mq('medium') {
                visibility: visible;
            }
        }

        #{$this}__results-query-header-default {
            display: none;
        }

        #{$this}__results-query-header-count {
            display: block;

            text-align: center;
        }

        #{$this}__suggestion-wrapper {
            display: block;
        }
    }

    &.is-no-results {
        #{$this}__categories {
            display: none;
        }

        #{$this}__no-results {
            display: flex;

            opacity: 0;
        }

        #{$this}__results {
            display: none;
        }

        #{$this}__results-query-header-default {
            display: none;
        }

        #{$this}__results-products-wrapper {
            display: none;
        }
    }

    &.is-no-results-in {
        #{$this}__no-results {
            opacity: 1;

            transition: opacity 0.4s $ease-in-out-soft;
        }
    }

    &.is-few-results {
        #{$this}__results-products-wrapper {
            justify-content: center;
        }
    }

    &.is-scroll-categories {
        #{$this}__query-field-wrapper {
            &:after {
                opacity: 0;
            }
        }
    }

    &.active {
        display: block;
    }

    &.is-init {
        opacity: 0;

        transition: none;
    }

    &.is-in {
        opacity: 1;

        transition: opacity 0.2s $ease-out-soft;

        #{$this}__query-field-wrapper {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.6s $ease-out-soft,
                transform 0.6s $ease-out-soft;
        }

        #{$this}__results {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.3s $ease-out-soft,
                transform 0.8s $ease-out-soft;
        }
    }

    &.is-first-in {
        $delay: 0.1s;

        #{$this}__categories-content-wrapper {
            opacity: 1;
            transform: none;

            transition:
                opacity 0.6s $ease-out-soft $delay + 0.16s,
                transform 0.6s $ease-out-soft $delay + 0.16s;
        }

        #{$this}__query-field-wrapper {
            transition-delay: $delay + 0s;
        }

        #{$this}__results {
            transition:
                opacity 0.6s $ease-out-soft $delay + 0.24s,
                transform (0.6s + $delay + 0.24s) $ease-out-soft;
        }

        #{$this}__results-products-wrapper {
            > * {
                opacity: 1;
                transform: none;

                @for $i from 1 through 12 {
                    &:nth-child(#{$i}) {
                        transition:
                            opacity 0.3s $ease-out-soft 0.06s * $i + $delay + 0.32s,
                            transform 0.6s $ease-out-soft 0.06s * $i + $delay + 0.32s;
                    }
                }

                @include mq('medium') {
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            transition:
                                opacity 0.3s $ease-out-soft 0.06s * $i + $delay + 0.32s,
                                transform 0.6s $ease-out-soft 0.06s * $i + $delay + 0.32s;
                        }
                    }
                }
            }
        }
    }

    &.is-out {
        opacity: 0;

        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.3s $ease-out-soft;
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    > #{$this}__scrolling-wrapper {
        position: relative;

        display: block;
    }

    > #{$this}__query-field-wrapper {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__query-field-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        width: 100%;
        padding: ($spacer * 3) var(--bs-gutter-x);

        background: $white;

        opacity: 0;
        transform: translateY(3rem);

        @include mq('medium') {
            padding: ($spacer * 5) var(--bs-gutter-x) 0;
        }

        &:before {
            position: absolute;
            top: auto;
            left: 0;
            bottom: -($spacer * 6);
            content: '';

            display: none;

            width: 100%;
            height: ($spacer * 6);

            background: linear-gradient(to bottom, rgba($white, 1) 20%, rgba($white, 0) 100%);

            pointer-events: none;

            @include mq('medium') {
                display: block;
            }
        }
    }

    &__scrolling-wrapper {
        @include is-absolute-fs;
        z-index: 1;

        // height minus search input element height - prevents scroll cutoff bug
        height: calc(100% - 88px);

        overflow: hidden auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;

        @include mq('medium') {
            height: 100%;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;

            appearance: none;
        }

        .suggestions {
            @include mq('medium') {
                padding-bottom: 8.5rem;
            }
        }
    }

    &__results-categories {
        width: 100%;

        @include mq('medium') {
            width: 100%;
            max-width: 63rem;
        }
    }

    &__query-field {
        width: 100%;
        max-width: 64rem;

        figure {
            left: 1rem;

            display: flex;
            justify-content: center;
            align-items: center;

            @include mq('medium') {
                top: calc(50% - 1.3rem);
            }
        }

        .field-search__visible-text {
            left: 0;
            z-index: 0;

            display: block;

            width: 100%;
            padding: 0;

            color: transparent;

            border-radius: 3rem;

            background: $grey-white;
        }

        .field-search {
            input {
                left: 0;

                width: 75%;
                margin: 0 0 0 35px;
                padding: 0;

                color: $black;

                border-radius: 0;
            }

            &__high-char-count {
                right: 0;

                padding: 0 2.5rem 0 0;

                white-space: nowrap;
                text-align: right;
                text-overflow: ellipsis;

                transform: translateX(-40px);

                overflow: hidden;

                &:before {
                    position: absolute;
                    content: '';

                    width: 40px;
                    height: 100%;

                    border-radius: 40px 0 0 40px;

                    background: $white-alabaster;

                    transform: translateX(40px);
                }
            }
        }
    }

    &__suggestion-wrapper__phrase {
        .h8 {
            &:first-child {
                @include font-family('medium');

                color: $grey-silver;
            }

            &:last-child {
                @include font-family('bold');
            }
        }
    }

    &__categories {
        padding: 0 var(--bs-gutter-x) ($spacer * 3);

        background: $white;

        @include mq('medium') {
            display: block;

            padding: ($spacer * 5) var(--bs-gutter-x);
        }

        > * {
            position: relative;
            z-index: 1;

            width: 100%;
            max-width: 69rem;
            margin: 0 auto;
        }

        li {
            margin-top: $spacer * 2;

            list-style: none;

            // limit mobile to 5 suggestions
            @include mq('medium', 'max') {
                &:nth-of-type(1n + 6) {
                    display: none;
                }
            }

            &:first-child {
                margin-top: 0;
            }

            a {
                @include underline-styles;
                text-decoration-line: none;

                &:hover {
                    text-decoration-line: underline;
                }
            }

            span {
                @include p-lg;
                @include font-family('medium');

                strong {
                    @include font-family('bold');
                }

                sup {
                    @include font-family('bold');
                    margin-left: $spacer;

                    font-size: 0.6em;
                    vertical-align: super;
                }
            }

            &.selected {
                display: inline-block;

                border: 1px solid $blue-brand; // imitating native focus
                border-radius: 0.4rem;

                outline: 1px solid $white;
            }
        }
    }

    &__categories-content-wrapper {
        opacity: 0;
        transform: translateY(3rem);
    }

    &__categories-header {
        @include h7;

        position: relative;

        display: block;

        margin-bottom: $spacer * 2;

        font-weight: $font-weight-medium;
    }

    &__suggestion-wrapper {
        position: relative;

        display: none;

        margin-bottom: $spacer * 2;

        @include mq('medium') {
            margin-bottom: 3.5rem;
        }

        span {
            position: relative;

            display: inline-block;
        }
    }

    &__suggestion-header {
        position: relative;

        display: block;

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                text-decoration: none;

                cursor: pointer;
            }
        }
    }

    &__results {
        position: relative;

        display: block;
        flex-flow: row wrap;
        justify-content: center;

        width: 100%;
        margin: 0 auto;
        padding: 0 var(--bs-gutter-x);

        opacity: 0;
        transform: translateY(11rem);

        @include mq('medium') {
            position: relative;
            top: 0;

            text-align: center;
        }

        @include mq('large') {
            text-align: center;

            overflow: hidden;
        }

        &.results__is-empty {
            display: none;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 0;

            display: none;

            width: 100%;
            height: 100%;

            background: $white;
        }

        > * {
            position: relative;
            z-index: 1;
        }
    }

    &__results-query-header-default {
        display: block;

        margin-top: 3.8rem;

        opacity: 0;
        transform: translateY(3rem);

        transition:
            opacity 0.6s $ease-out-soft,
            transform 0.6s $ease-out-soft;
    }

    &__results-query-header-count {
        display: none;

        padding-top: 3.8rem;

        background: $white;

        opacity: 0;
        transform: translateY(3rem);

        transition:
            opacity 0.6s $ease-out-soft,
            transform 0.6s $ease-out-soft;
    }

    &__results-query-header-count-cta {
        top: 0.3rem;

        @include mq('large') {
            top: 0;
        }
    }

    &__results-query-header-wrapper {
        position: relative;

        display: none;

        span {
            position: relative;

            display: block;
        }
    }

    &__results-query-header {
        position: relative;

        display: block;
    }

    &__results-query-subheader {
        position: relative;

        display: block;
    }

    &__results-products-wrapper {
        display: flex;
        flex-flow: row wrap;

        background: $white;

        > * {
            opacity: 0;
            transform: translateY(3rem);
        }
    }

    &__results-products-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        margin-bottom: ($spacer * 2);

        @include mq('medium') {
            align-items: baseline;
        }

        > p {
            @include h7;
        }

        > .stretched-link {
            padding: 0;
        }
    }

    &__results-more-cta-wrapper {
        display: none;
        justify-content: center;

        margin: 3rem 0 6rem;

        opacity: 0;
        transform: translateY(3rem);

        transition:
            opacity 0.6s $ease-out-soft,
            transform 0.6s $ease-out-soft;

        @include mq('medium') {
            display: flex;

            padding: 0;

            visibility: hidden;
        }
    }

    &__results-more-cta {
        position: relative;

        flex: 0 0 auto;
    }

    .search-field {
        position: relative;
        left: 3rem;

        width: 75%;
        padding-left: 1rem;

        text-transform: lowercase;

        border-radius: 0;

        background: transparent;

        @include mq('small') {
            width: 85%;
        }

        @include mq('medium') {
            left: 3rem;

            width: 75%;
        }

        input {
            color: $black;
        }

        &::placeholder {
            text-transform: none;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .modal-close {
        position: static;
        top: 0;
        left: 100%;

        button {
            @include underline-styles;
            padding-right: 0;
            padding-left: $spacer * 2;

            text-decoration-line: underline;

            border: 0;

            @include underline-styles;

            &:active {
                border-color: transparent;

                box-shadow: none;
            }
        }
    }

    &__clear-query {
        position: absolute;
        top: 0;
        right: 1rem;
        z-index: 11;

        width: 46px;
        height: 40px;

        transform: translateX(10px);

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 5;

            width: 1rem;
            height: 1rem;

            transform: translate3d(-50%, -50%, 0);

            * {
                width: 1rem;
                height: 1rem;

                fill: $white;
            }
        }

        &__fill {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;

            width: 3.2rem;
            height: 3.2rem;

            border-radius: 100px;

            background: $black;

            transform: translate3d(-50%, -50%, 0);
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.search-overlay.search-overlay--no-initial-results {
    height: auto;
}
