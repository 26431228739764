@use 'sass:map';

html {
    font-size: $rem-base;
    font-synthesis-weight: none;
}

body,
input,
button {
    @include font-family('body');

    font-size: $font-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;

    :lang(ja) & {
        line-height: $body-line-height * 1.1;
    }
}

button {
    color: var(--bs-btn-color, $black);
}

.is-dark,
.dark-theme {
    a:not(.btn, .filter-refine-pills__pill) {
        color: var(--pat-theme-fore, $white);
    }
}

// @todo: consolidate global layout
.page-wrapper {
    padding: 0;

    &.is-dark,
    .themed.is-dark {
        color: $white;

        background-color: var(--pat-theme-back, $black);
    }

    .themed.is-light {
        color: $black;
    }

    .themed.is-light:not(.modal) {
        background-color: var(--pat-theme-back, $white);
    }

    // theme classNames for brand colors
    @each $new-color, $color in $brand-colors {
        &.is-#{$new-color},
        .themed.is-#{$new-color} {
            color: $white;

            background-color: $color;
        }
    }
}

.page {
    position: relative;

    &__section {
        margin: 0;
        padding: 0;

        border: none;
    }
}

@supports (hyphenate-limit-chars: auto) {
    [name='main-content'] {
        ~ div,
        ~ article,
        ~ section {
            p {
                hyphens: auto;
                hyphenate-limit-chars: 14 9 3;
            }
        }
    }
}

.error-page {
    padding-bottom: 15rem;
}

a {
    @include underline-styles;

    @include focus-visible {
        outline: 0.2rem $blue-utility auto;
    }

    &.underlined {
        @include a;
    }
}

u {
    @include underline-styles;
}

/* p */

p,
.p {
    @include p;
}

/* p-snippet */

.p-snippet {
    font-size: 1.4rem;
    line-height: 1.7;

    // max-width: 20rem;

    &--special {
        @include font-family('serif');

        font-size: 1.5rem;
        line-height: 1.6;

        // max-width: 23rem;
    }
}

/* p-sm */

p.sm,
.p-sm {
    @include p-sm;
}

/* p-xs */

p.xs,
.p-xs {
    @include p-xs;
}

/* p-lg */

p.lg,
.p-lg {
    @include p-lg;
}

/* p-xl */

p.xl,
.p-xl {
    @include p-xl;
}

p {
    /* embedded links */

    a:not(.btn) {
        @include a;

        display: inline;
    }
}

/* variations */

.demi {
    @include font-family('demi');
}

.medium {
    @include font-family('medium');
}

// Legacy font, setting to secondary if class still used
p.font-special-sentinel,
.font-special-sentinel {
    @include font-family('serif');
}

p.font-special-lusitana,
.font-special-lusitana {
    @include font-family('special-lusitana');
}

.bold,
strong {
    @include font-family('bold');
}

sup {
    top: 0;
    bottom: auto;

    display: inline-block;

    font-size: 1em;
    line-height: inherit;
    letter-spacing: -0.013rem;
    vertical-align: top;
}

sub {
    top: 0;
    bottom: auto;

    display: inline-block;

    font-size: 0.7em;
    line-height: inherit;
    letter-spacing: -0.01rem;
    vertical-align: bottom;
}

small {
    font-size: 0.75em;
}

abbr {
    position: relative;

    display: inline-block;

    &[title] {
        line-height: 1;
        text-decoration: none;

        border-bottom: 1px dotted $grey;
    }

    // TODO: Bootstrap Tooltip (for interactions)
}

mark {
    position: relative;

    display: inline-block;

    margin: 0 -0.2rem;
    padding: 0 0.2rem;

    color: $white;
    font-weight: inherit;

    background: $grey;
}

q,
blockquote {
    &:before,
    &:after {
        position: relative;

        display: inline-block;

        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
    }

    &:before {
        content: '\“';
    }

    &:after {
        content: '\”';
    }
}

hr {
    margin: 3rem 0;

    border: none;
    border-bottom: 0.1rem solid $grey-silver;

    opacity: 1;
}

ol.is-styled,
ul.is-styled {
    list-style-type: none;

    li {
        @include font-family('medium');
        position: relative;

        padding: 0 0 0 4.5rem;

        font-size: 1.75rem;
        line-height: 2;
    }
}

ol.is-styled {
    counter-reset: item;

    li {
        counter-increment: item;

        &:before {
            position: absolute;
            top: 0;
            left: 1.9rem;
            content: counter(item) '.';

            line-height: inherit;
        }
    }
}

ul.is-styled {
    li {
        &:before {
            position: absolute;
            top: calc(map.get($body-mobile-sizes, p) / 2);
            left: 1.9rem;
            content: '';

            width: 0.6rem;
            height: 0.6rem;

            border-radius: 50%;

            background: $black;

            @include mq('large') {
                top: calc(map.get($body-desktop-sizes, p) / 2);
            }
        }
    }
}

.show-unregistered,
.show-pro {
    display: none;
}

.user-unregistered {
    .show-registered {
        display: none;
    }

    .show-unregistered {
        display: block;
    }
}

.user-pro {
    .show-pro {
        display: block;
    }

    .show-non-pro {
        display: none;
    }
}

.user-non-pro {
    .show-non-pro {
        display: block;
    }

    .show-pro {
        display: none;
    }
}

figure[style*='--focal-point-x'][style*='--focal-point-y'] img,
img[style*='--focal-point-x'][style*='--focal-point-y'] {
    object-position: var(--focal-point-x) var(--focal-point-y);
}
