// from modules/sliders/slider-cards/style.scss

$cards-height-bump-breakpoint: 800px;

.slider-cards {
    position: relative;

    padding: 5.6rem 0 0;

    @include mq('large') {
        padding: 5.6rem 0 10.4rem;
    }

    @include mq('medium') {
        padding: 8rem 0;

        @media (min-height: $cards-height-bump-breakpoint) {
            padding: 11.4rem 0 12rem;
        }
    }

    ////////////////////////////////////////////////////////////////
    // VARIATIONS

    &.layout-wide {
        .swiper-slide {
            flex: 0 0 50%;

            max-width: 50%;

            @include mq('medium', 'max') {
                flex: 0 0 100%;

                max-width: 100%;
            }
        }
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    &.has-btn-after {
        padding-bottom: 6rem;
    }

    &.is-flush {
        padding: 0;
    }

    &.is-grey {
        background: $white-concrete;
    }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            &:not(.swiper-button-disabled) {
                opacity: 1;
            }
        }
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    .card {
        margin-bottom: $spacer * 5;
    }

    &__header {
        $this: &;

        display: block;

        height: auto;
        margin: 0 0 $spacer * 3;

        overflow: visible;

        @include mq('medium') {
            margin-bottom: $spacer * 3;
        }

        &.is-after-slider {
            margin-top: 0;
        }
    }

    &__header-inner a {
        &,
        section .themed.is-light & {
            color: $black;
            text-decoration: none;
        }

        .is-dark &,
        section .themed.is-dark & {
            color: $white;
        }
    }

    &__header-title {
        @include h5;
        display: inline-block;

        max-width: 40em;
        margin-bottom: 0;

        line-height: 1 !important;
        text-align: left;

        @include mq('medium') {
            @include h4;
        }

        @include mq('large') {
            margin-top: 0;
        }
    }

    &__header-count {
        @include underline-styles;
        @include p;

        position: relative;

        display: inline;

        margin-left: $spacer * 0.5;

        &:hover {
            text-decoration-line: underline;
        }

        &,
        section .themed.is-light & {
            color: $black;
            text-decoration-line: none;

            &:after {
                background: $black;
            }
        }

        .is-dark &,
        section .themed.is-dark & {
            color: $white;
        }
    }

    &__header-btn {
        padding-top: 0.125rem;

        figure {
            margin-right: 0.5rem;
        }

        svg {
            width: 1.2rem;
            height: 1.6rem;
            margin-top: 0.1rem;
        }
    }

    &__header-subhead {
        max-width: 70rem;
        margin-top: $spacer * 2;

        text-align: center;

        @include mq('medium') {
            text-align: left;
        }

        &:empty {
            margin-top: 0;
        }
    }

    // features slider section

    &__container {
        width: 100%;
        margin-bottom: 4rem;
        padding-bottom: 4.4rem;

        @include mq('xlarge') {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .swiper {
            overflow: visible;

            &:not(.swiper-initialized) {
                .swiper-wrapper {
                    width: unset;
                    margin-right: calc(var(--bs-gutter-x) * -0.5);
                    margin-left: calc(var(--bs-gutter-x) * -0.5);
                    box-sizing: border-box;
                }
            }

            &.swiper-initialized {
                .swiper-slide {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .swiper-wrapper {
            height: auto;
        }

        .swiper-slide {
            flex: auto;
            flex-grow: 0;
            flex-shrink: 0;

            max-width: none;
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);

            &:last-child {
                position: relative;

                &:after {
                    position: absolute;
                    right: -3rem;
                    content: '';

                    display: block;

                    width: 3rem;
                    height: 1px;

                    @include mq('medium') {
                        right: -5rem;

                        width: 5rem;
                    }

                    @include mq('large') {
                        right: -7rem;

                        width: 7rem;
                    }

                    body:not(.is-user-touch) & {
                        display: none;
                    }
                }
            }

            &__image {
                position: relative;

                width: 100%;
                padding-top: calc(480 / 629 * 100%);

                img {
                    @include object-fit(cover);
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 100%;
                }
            }

            &__caption {
                position: absolute;
                top: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                width: calc(100% - 4rem);
                height: calc(100% - 4rem);
                padding: 2rem;

                span {
                    @include h3;

                    display: block;

                    margin: 0 auto;

                    color: $white;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: calc(50% - 0.8rem);
            right: -3.5rem;

            width: auto;
            height: auto;

            background-image: none;

            transition:
                opacity 0.4s $ease-out-soft,
                transform $button-out-time $ease-hover;

            @include mq('xlarge', 'max') {
                right: -2.5rem;
            }

            @include mq('large', 'max') {
                right: $spacer * -2;
            }

            @include mq('medium', 'max') {
                right: 0;
            }

            &:hover {
                transform: scale(1.05);
            }

            figure {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 6rem;
                height: 6rem;

                border-radius: 50%;

                @include mq('medium', 'max') {
                    width: 3rem;
                    height: 3rem;
                }

                &,
                section .themed.is-light & {
                    @include DropShadow(4);
                    background-color: var(--pat-theme-cta-back, $white);
                }

                .is-dark &,
                section .themed.is-dark & {
                    @include DropShadow(4, $grey);
                    background-color: var(--pat-theme-cta-back, $black);
                }
            }

            svg {
                @include mq('medium', 'max') {
                    width: 1.2rem;
                    height: 1.2rem;
                }

                &,
                section .themed.is-light & {
                    fill: var(--pat-theme-cta-fore, $black);
                }

                .is-dark &,
                section .themed.is-dark & {
                    fill: var(--pat-theme-cta-fore, $white);
                }
            }
        }

        .swiper-button-prev {
            left: -3.5rem;
            right: auto;

            @include mq('large', 'max') {
                left: -2.5rem;
            }

            @include mq('medium', 'max') {
                left: -1rem;
            }

            svg {
                transform: rotate(180deg);
            }
        }

        .swiper-button-disabled {
            opacity: 0;
        }
    }

    &--fpc {
        padding: 10rem 0 0;

        @include mq('xlarge') {
            padding: 16rem 0;
        }

        .slider-cards__container {
            @include mq('medium', 'max') {
                padding-right: 3rem;
                padding-left: 3rem;
            }
        }

        .slider-cards__header {
            margin-right: 0;
            margin-left: 0;

            overflow: visible;

            @include mq('xlarge') {
                margin-right: calc((25% / 3) - (var(--bs-gutter-x) * 0.5));
                margin-left: calc((25% / 3) - (var(--bs-gutter-x) * 0.5));
            }
        }

        .slider-cards__header-title {
            text-align: left;

            h3 {
                @include h4;

                @include mq('medium') {
                    @include h2;
                }
            }
        }

        .slider-cards__header-btn {
            margin-top: 3rem;
        }

        .swiper {
            margin-left: 0;

            @include mq('xlarge') {
                margin-left: calc((25% / 3) - (var(--bs-gutter-x) * 0.5));
            }
        }

        .cta-progress {
            bottom: 0;

            @include mq('large') {
                bottom: calc(100% + 8rem);
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 40%;

            @include mq('large') {
                top: 50%;
            }
        }
    }

    &--inverse {
        padding: 10rem 0 0;

        color: $white;

        background: $grey-darker;

        @include mq('large') {
            padding: 17rem 0 23.5rem;
        }

        .slider-cards__header {
            margin: 0;
        }

        .slider-cards__header-title {
            text-align: left;
        }

        .swiper {
            margin-left: 0;

            @include mq('xlarge') {
                margin-left: calc((25% / 3) - (var(--bs-gutter-x) * 0.5));
            }
        }

        .swiper-wrapper {
            margin-top: 6.6rem;
        }

        .card {
            border: 0;
        }

        .cta-progress {
            right: 0;

            background: $grey-dark;

            @include mq('xlarge') {
                right: calc((25% / 3) - (var(--bs-gutter-x) * 0.5));
            }

            &__text-all {
                color: $white;
            }

            &__text-qty {
                color: $black;
            }

            &__head {
                background: $white;
            }
        }
    }

    &.slider-story-film,
    &.slider-story-general {
        padding: 0;

        .slider-cards__container {
            padding: 0;

            @include mq('medium', 'max') {
                max-width: 100%;
            }
        }
    }
}
