// Used in Account Pages - other pages?
.standard-breadcrumb {
    display: flex;

    height: 6rem;
    margin-bottom: 2rem;
    padding: 0 4rem;

    @include mq('medium') {
        padding: 0 8rem;
    }

    &-item {
        display: flex;
        align-items: center;

        a {
            @include h7;
            @include font-family('demi');
            @include underline-styles;

            color: $black;

            @include mq('large') {
                @include h9;
            }

            &:hover {
                text-decoration-line: underline;

                cursor: pointer;
            }
        }

        &:last-child {
            @include h7;
            @include font-family('medium');

            @include mq('large') {
                @include h9;
            }
        }

        .icon--chevron-right {
            fill: $grey;
        }

        + .standard-breadcrumb-item {
            padding-left: 0.5rem;
        }
    }

    &-arrow {
        @include breadcrumb-arrow;

        bottom: 0.1rem;
    }
}

.breadcrumb-item {
    @include p-xs;

    display: flex;
    flex-flow: row;
    align-items: baseline;

    white-space: nowrap;

    @include mq('large', 'min') {
        a {
            @include font-family('medium');
        }
    }

    a,
    span,
    strong,
    figure {
        @include font-family('medium');
    }

    &:last-child {
        span,
        strong,
        figure {
            font-weight: 500;
        }
    }

    a,
    a:hover {
        color: $black;
    }

    a {
        @include font-family('medium');
        @include underline-styles;

        text-decoration-line: none;

        &:hover {
            text-decoration-line: underline;
        }
    }
}

.breadcrumb-scroll-wrapper {
    position: relative;

    display: grid;
    flex-wrap: nowrap;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        content: '';
        z-index: 11;

        display: block;

        width: var(--bs-gutter-x);

        pointer-events: none;
    }

    &::before {
        left: 0;

        background: linear-gradient(-90deg, rgba($white, 0), rgba($white, 1) 50%);

        .is-dark & {
            background: linear-gradient(-90deg, rgba($black, 0), rgba($black, 1) 50%);
        }
    }

    &::after {
        right: 0;

        background: linear-gradient(90deg, rgba($white, 0), rgba($white, 1) 50%);

        .is-dark & {
            background: linear-gradient(90deg, rgba($black, 0), rgba($black, 1) 50%);
        }
    }

    > div,
    > ol {
        overflow: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
    }
}

.breadcrumb {
    flex-wrap: nowrap;
    align-items: baseline;

    margin-bottom: 0;

    li:last-child {
        padding-right: 4rem;
    }
}

.breadcrumb-inline {
    position: relative;
    z-index: 10;

    margin-top: 2rem;
    margin-bottom: 2rem;
}

.navigation-subnav-content__breadcrumbs-arrow {
    @include breadcrumb-arrow;
    display: inline-block;
    align-self: center;
}

.page-pdp,
.page-plp-cat {
    .breadcrumb-scroll-wrapper {
        display: block;

        margin: 0 ($spacer * -2);

        .breadcrumb-wrapper {
            padding: 0 ($spacer * 2);
        }
    }

    .breadcrumb-item {
        display: none;

        padding-left: 0;

        @include mq('large') {
            display: flex;

            padding-left: var(--bs-breadcrumb-item-padding-x);
        }

        &::before {
            display: none;
        }

        &:nth-last-child(2) {
            display: flex;
        }

        &:first-child {
            .navigation-subnav-content__breadcrumbs-arrow {
                @include mq('large') {
                    display: none;
                }
            }
        }

        .navigation-subnav-content__breadcrumbs-arrow {
            @include mq('large', 'max') {
                margin: 0 ($spacer * 0.5) 0 0;

                transform: rotate(180deg);
            }
        }

        &__mobile {
            @include p-xs;

            display: flex;

            @include mq('large') {
                display: none;
            }
        }

        &__desktop {
            display: none;

            @include mq('large') {
                display: flex;
            }
        }
    }
}

.page-plp-cat {
    .breadcrumb-wrapper {
        margin: 0;
    }
}

.page-pdp {
    .breadcrumb {
        a {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
    }

    .page-pdp-2-col {
        &__breadcrumb-desktop {
            grid-column: 1/13;

            display: none;

            @include mq('large') {
                display: block;
            }
        }

        &__breadcrumb-mobile {
            display: block;

            padding: 0 ($spacer * 2);

            @include mq('large') {
                display: none;
            }
        }
    }

    .breadcrumb-scroll-wrapper {
        @include mq('large') {
            grid-column: 1 / 13;
        }

        .breadcrumb-wrapper {
            margin-top: $spacer;
            margin-bottom: 0;

            @include mq('large') {
                margin: $spacer 0;
            }
        }
    }
}

// PDP Sticky Header Breadcrumbs
.page-pdp__sticky-header {
    .breadcrumb-scroll-wrapper {
        margin-right: var(--bs-gutter-x);
        margin-left: calc(var(--bs-gutter-x) * -1);
    }

    .breadcrumb {
        padding: 0 var(--bs-gutter-x);

        overflow: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;

        @include ie {
            width: 66%;
        }

        &-item {
            @include underline-styles;

            span:hover {
                text-decoration-line: underline;

                cursor: pointer;
            }
        }
    }
}
