/*
 *  from bits/card/style.scss
 *
 *  card types:
 *  --manual-content
 *  --article-content (through Wordpress)
 *  --film content
 *  --paw-event, --paw-petition, --paw-volunteer, --paw-grantee
 *  --review
 *  --feature
 *  --image
 *  --chip (very similar to --image but with no bg image)
 *  --fpc-material-content
 *  --fpc-facility-content
 *  --view-all
 *  --router
 *
 *  ALL HOVER EFFECTS
 */

@use 'sass:map';
@use 'sass:math';
@use 'sass:color';
@import '../variables';

// Caution: IE/Edge don't do calc() and transforms, so need both these. If one is changed make sure the total is 100%
$image-top-placeholder: 33%;
$calc-height-minus-placeholder: 67%;

.card-tile {
    $this: '.card';
    position: relative;

    background-color: transparent;

    cursor: pointer;

    // common card styles and bits
    backface-visibility: hidden;

    @include media-breakpoint-down('sm') {
        &.swiper-slide-active {
            &:before {
                opacity: 1;
            }

            #{$this}__meta-cta.btn-outlined.btn-dark {
                color: $white;

                background-color: $black;

                transition:
                    transform $button-out-time $ease-hover,
                    color $button-out-time $ease-hover,
                    background-color $button-out-time $ease-hover;
            }

            #{$this}__meta-cta.btn-outlined.btn-light {
                color: $black;

                background-color: $white;

                transition:
                    transform $button-out-time $ease-hover,
                    color $button-out-time $ease-hover,
                    background-color $button-out-time $ease-hover;
            }
        }
    }

    // All cards have the same overall aspect ratio
    .card__inner {
        position: relative;
        z-index: 1;

        padding-top: (math.div(500, 415) * 100%);

        border-radius: $border-radius;

        overflow: hidden;

        will-change: transform;

        transition: transform 0.3s $ease-out-soft;
    }

    .card__inner,
    section .themed.is-light &:not(.card--paw, .card--router) .card__inner {
        background-color: $grey-white;
    }

    .is-dark .card__inner,
    section .themed.is-dark &:not(.card--paw, .card--router) .card__inner {
        background-color: $grey-darkest;
    }

    .card__link-full {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: block;

        width: 100%;
        height: 100%;

        text-indent: -9999px; // visually conceal text
    }

    // All content starts at the same top level
    .card__content-wrapper {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: $calc-height-minus-placeholder;

        will-change: height;

        transition: height 0.3s $ease-out-soft;
    }

    .card__content {
        position: relative;
        z-index: 1;

        padding: 3rem 3.2rem 2rem;

        color: $white;
    }

    .card__image-wrap {
        position: relative;

        padding-top: calc(240 / 412 * 100%);

        overflow: hidden;
    }

    .card__image,
    .card__image img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        backface-visibility: hidden;
    }

    .card__image img,
    img.card__image {
        @include object-fit(cover);

        background-color: transparent;

        transition:
            transform 0.8s $ease-out-soft,
            filter 0.4s,
            filter 0.4s,
            opacity 0.2s;
    }

    .card__svg {
        position: absolute;
        top: 50%;
        left: 25%;
        z-index: 1;

        width: 50%;
        height: 0;
        padding-bottom: 50%;

        transform: translateY(-50%);

        transition: opacity 0.3s $ease-out-soft;

        .layout-wide & {
            left: 33.333%;

            width: 33.333%;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        + #{$this}__content-wrapper #{$this}__title {
            opacity: 0;
        }
    }

    .card__title {
        @include h5;

        transition: opacity 0.3s $ease-out-soft;

        @include mq('large') {
            @include h6;
        }

        span {
            font-weight: inherit;
        }

        + #{$this}__blurb-wrap {
            margin-top: 1rem;
        }
    }

    .card__meta-primary,
    .card__meta-secondary {
        display: block;
    }

    .card__meta-tags {
        @include p-xs;

        left: 3.2rem;
        z-index: 1;

        max-width: 100%;
        margin-top: 1.2rem;

        font-weight: $font-weight-bold;
        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: hidden;

        transition: opacity 0.15s $ease-out-soft;

        a,
        a:hover {
            text-decoration: none;
        }

        span {
            color: $white;
        }
    }

    .card__meta-cta {
        position: absolute;
        left: 3.2rem;
        bottom: 2rem;
        z-index: 2;

        max-width: 8rem;
        box-sizing: content-box;

        background-color: transparent;

        @include mq('medium') {
            max-width: unset;
        }
    }

    .card__meta-cta.btn-outlined {
        transition:
            transform $button-out-time $ease-hover,
            color $button-out-time $ease-hover,
            background-color $button-out-time $ease-hover;
    }

    .card__arrow {
        position: absolute;
        top: 1.5rem;
        right: var(--bs-gutter-x);

        width: 1.2rem;
        height: 1.2rem;
    }

    .card__badge {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;

        .cta-rounded-rect__heading {
            width: auto;
            padding: 0 1.25rem;

            font-weight: 600;
            font-size: 1rem;
            line-height: 2.4rem;
        }
    }

    .card__blurb-wrapper {
        position: relative;

        height: 0;
        margin-top: 0.8rem;

        opacity: 0;

        overflow: hidden;

        will-change: height;

        transition:
            height 0.3s $ease-out-soft,
            opacity 0.4s $ease-in-out-soft 0.1s;
    }

    .card__blurb,
    .card__blurb p {
        @include p;
    }

    // Cards drop shadow
    &:not(.card__no-dropshadow) {
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            z-index: 0;

            width: 85%;
            height: 50%;

            background-color: transparent;

            box-shadow: 0 $spacer ($spacer * 4) 0 rgba($black, 0.3);

            opacity: 0;
            transform: translateX(-50%);

            transition: opacity 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                display: none;
            }
        }

        &:hover:before {
            opacity: 1;
        }
    }

    // Baseline Content Card
    &.card--content {
        position: relative;

        box-sizing: content-box;

        cursor: pointer;

        @include mq('large', 'max') {
            #{$this}__title {
                transform: translateY(50%);
            }

            #{$this}__image-wrapper {
                &:after {
                    opacity: 1;
                }
            }

            #{$this}__blurb-wrapper {
                position: relative;
                bottom: 0;

                display: block;

                height: auto !important;
                margin-top: 0.75rem;

                opacity: 1;
            }
        }

        #{$this}__image-wrapper {
            position: absolute;
            top: 0;
            z-index: 0;

            width: 100%;
            height: 40%;

            &:after {
                position: absolute;
                top: -1px;
                left: 0;
                content: '';
                z-index: 1;

                width: calc(100% + 1px);
                height: 100%;

                background-image: linear-gradient(-180deg, #333 0%, rgba(51, 51, 51, 0) 100%);

                opacity: 0;

                transition: opacity 0.3s $ease-out-soft;
            }
        }

        #{$this}__image {
            @include object-fit(cover);
        }

        #{$this}__arrow {
            transition: transform $button-out-time $ease-hover;
        }

        #{$this}__title {
            @include h5;

            position: relative;
            z-index: 1;

            height: auto !important;
            max-width: 32rem;
            margin-bottom: 2.4rem;

            transition: transform 0.4s $ease-out-soft 0s;

            @include mq('xxxlarge') {
                @include h4;
            }

            span {
                display: block;

                width: 100%;
                margin-top: 0.8rem;
                margin-bottom: 0.8rem;
            }
        }

        #{$this}__blurb-wrapper {
            position: absolute;
            left: 0;
            bottom: calc(-100% - 1rem);

            flex: 0 0 100%;

            margin-top: 0;
        }

        &:not(.card--paw-event, .card--paw-petition, .card--paw-volunteer) {
            &:not(.card--paw-grantee) {
                #{$this}__blurb-wrapper {
                    @include mq('large', 'max') {
                        height: auto;

                        opacity: 1;
                    }
                }
            }
        }

        #{$this}__blurb {
            max-width: 15em;

            @include mq('large', 'max') {
                font-size: 1.2rem;
            }

            @include mq('xxxlarge') {
                @include p;
            }
        }

        #{$this}__content {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;

            width: calc(100% - 4.8rem);
            height: 60%;
            padding: 0 2.4rem;

            color: $white;

            background-color: transparent;
        }

        #{$this}__meta-primary {
            position: absolute;
            top: 2.4rem;
            left: 2.4rem;
            z-index: 2;

            opacity: 0;
            transform: scale(0.8);
            transform-origin: left center;

            transition:
                transform 0.3s $ease-out-soft 0s,
                opacity 0.3s $ease-out-soft 0s;

            @include mq('large', 'max') {
                opacity: 1;
                transform: none;
            }

            a {
                position: relative;

                padding: 0 1.6rem;
                padding-right: 4rem;
            }

            svg {
                position: relative;
                top: 0.3rem;
                right: 3.5rem;
            }
        }

        #{$this}__badge {
            position: absolute;
            top: 2.8rem;
            left: 2.4rem;
            z-index: 1;

            opacity: 1;
            transform: none;
            transform-origin: left center;

            pointer-events: none;

            transition:
                transform 0.3s ease 0.1s,
                opacity 0.3s ease 0.1s;

            a {
                color: $white;
                line-height: 1.6rem;

                background-color: rgba($black, 0.3);
            }
        }
    }

    // Manullly created through BM
    &.card--manual-content {
        // Styles for extra large screens
        #{$this}__inner {
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                z-index: 0;

                width: 100%;
                height: 100%;

                background: rgb(0, 0, 0);
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.3) 20%,
                    rgba(0, 0, 0, 0.6) 40%,
                    rgba(0, 0, 0, 1) 100%
                );

                opacity: 0.8;

                transition: opacity 0.3s $ease-out-soft;
            }
        }

        &.card--no-overlay {
            #{$this}__inner {
                &:after {
                    display: none;
                }
            }
        }

        #{$this}__title span,
        #{$this}__blurb {
            display: -webkit-box;
            display: box;

            overflow: hidden;

            /* autoprefixer: off */
            -webkit-box-orient: vertical;

            // IE fallback for truncated text
            @include ie {
                position: relative;

                overflow: hidden;

                &:after {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    content: '';

                    width: 50%;
                    height: 1.5em;

                    text-align: right;

                    background: linear-gradient(
                        to right,
                        rgba($grey-white, 0),
                        rgba($grey-white, 1) 50%
                    );
                }
            }

            /* autoprefixer: on */
        }

        #{$this}__title {
            span {
                // IE fallback for truncated text
                @include ie {
                    display: inline-block;

                    height: 2.4em;

                    &:after {
                        height: 1.2em;
                    }
                }
            }
        }

        #{$this}__blurb-wrap {
            opacity: 0;

            transition: opacity 0.3s $ease-out-soft;
        }

        #{$this}__blurb {
            -webkit-line-clamp: 7;

            // IE fallback for truncated text
            @include ie {
                height: 9em;
            }
        }

        #{$this}__image-wrapper {
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                z-index: 0;

                width: 100%;
                height: 100%;

                background: $black;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.6) 0%,
                    rgba(0, 0, 0, 0.75) 100%
                );

                opacity: 0;

                transition: opacity 0.3s cubic-bezier(0.38, 0.41, 0.27, 1);
            }
        }
    }

    // Article card through Wordpress
    &.card--article-content {
        @include media-breakpoint-down('sm') {
            &.swiper-slide-active {
                #{$this}__meta-cta.btn-dark {
                    color: $black;

                    background-color: $white;

                    transition:
                        transform $button-out-time $ease-hover,
                        color $button-out-time $ease-hover,
                        background-color $button-out-time $ease-hover;
                }

                #{$this}__meta-cta.btn-light {
                    color: $white;

                    background-color: $black;

                    transition:
                        transform $button-out-time $ease-hover,
                        color $button-out-time $ease-hover,
                        background-color $button-out-time $ease-hover;
                }
            }
        }

        &:hover {
            #{$this}__blurb {
                transition: opacity 0.3s $ease-out-soft;
                transition-delay: 0.15s;
            }

            .card__authors {
                max-height: 0;

                opacity: 0;
            }

            .card__blurb-wrap + .card__authors {
                max-height: 1em;

                opacity: 1;
            }
        }

        #{$this}__inner {
            &,
            section .themed.is-light {
                background-color: $grey-white;
            }

            .is-dark &,
            section .themed.is-dark & {
                background-color: $grey-darkest;
            }

            &:after {
                display: none;
            }
        }

        #{$this}__image {
            height: $image-top-placeholder;

            + .ls-blur-up-img {
                height: $image-top-placeholder;
            }
        }

        #{$this}__title {
            @include font-family('serif');
            letter-spacing: $heading-letter-spacing-serif;

            // IE fallback for truncated text
            @include ie {
                height: 2.4em;

                span {
                    display: inline-block;

                    height: 2.4em;

                    &:after {
                        height: 1.2em;
                    }
                }
            }
        }

        #{$this}__content {
            transition: transform 0.3s $ease-out-soft;

            &,
            section .themed.is-light & {
                color: $black;
            }

            .is-dark &,
            section .themed.is-dark & {
                color: $white;
            }
        }

        #{$this}__content-wrapper:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            z-index: 1;

            width: 100%;
            height: 30%;

            &,
            section .themed.is-light & {
                background: linear-gradient(
                    to bottom,
                    rgba($grey-white, 0) 0%,
                    rgba($grey-white, 1) 50%
                );
            }

            .is-dark &,
            section .themed.is-dark & {
                background: linear-gradient(
                    to bottom,
                    rgba($grey-darkest, 0) 0%,
                    rgba($grey-darkest, 1) 50%
                );
            }
        }

        #{$this}__image-wrapper {
            opacity: 1;

            overflow: hidden;

            transition: opacity 0.3s $ease-out-soft;
        }

        #{$this}__title,
        #{$this}__blurb {
            p,
            span {
                display: -webkit-box;
                display: box;

                overflow: hidden;
                -webkit-line-clamp: 3;

                /* autoprefixer: off */
                -webkit-box-orient: vertical;

                // IE fallback for truncated text
                @include ie {
                    position: relative;

                    height: 3em;

                    overflow: hidden;

                    &:after {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        content: '';

                        width: 50%;
                        height: 1.5em;

                        text-align: right;

                        background: linear-gradient(
                            to right,
                            rgba($grey-white, 0),
                            rgba($grey-white, 1) 50%
                        );
                    }
                }

                /* autoprefixer: on */
            }
        }

        #{$this}__blurb-ellipsis,
        #{$this}__blurb {
            opacity: 1;

            transition: opacity 0.3s $ease-out-soft;
        }

        #{$this}__blurb {
            position: relative;

            width: 100%;
            height: 100%;

            text-wrap: balance;

            opacity: 0;

            transition: opacity 0.15s $ease-out-soft;

            p {
                -webkit-line-clamp: 4;

                // IE
                @include ie {
                    height: 9em;
                }
            }
        }

        #{$this}__blurb-wrap {
            margin-top: 1.2rem;
        }

        #{$this}__meta-cta {
            left: 3.2rem;
            right: auto;

            background-color: $white;

            transition: all 0.3s $ease-out-soft;

            &.btn-light:hover {
                border-color: $black;
            }

            &.btn-dark:hover {
                border-color: $white;
            }
        }

        #{$this}__meta-tags {
            a {
                color: $black;
            }
        }
    }

    // Film content
    &.card--film-content {
        #{$this}__inner {
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                z-index: 0;

                width: 100%;
                height: 100%;

                background: $black;
                background: linear-gradient(
                    180deg,
                    rgba($black, 0) 0%,
                    rgba($black, 0.3) 15%,
                    rgba($black, 0.6) 30%,
                    rgba($black, 0.8) 75%,
                    $black
                );
                background-size: auto 150%;

                opacity: 0.8;

                transition: background-position 0.3s $ease-out-soft;
            }
        }

        #{$this}__title span,
        #{$this}__blurb {
            display: -webkit-box;
            display: box;

            overflow: hidden;

            /* autoprefixer: off */
            -webkit-box-orient: vertical;

            // IE fallback for truncated text
            @include ie {
                position: relative;

                overflow: hidden;
            }

            /* autoprefixer: on */
        }

        #{$this}__title {
            @include font-family('serif');
            letter-spacing: $heading-letter-spacing-serif;

            span {
                // IE fallback for truncated text
                @include ie {
                    height: 2.4em;

                    &:after {
                        height: 1.2em;
                    }
                }

                // -webkit-line-clamp: 3;
            }
        }

        #{$this}__blurb-wrap {
            opacity: 0;

            transition: opacity 0.3s $ease-out-soft;
        }

        #{$this}__blurb {
            -webkit-line-clamp: 6;

            // IE fallback for truncated text

            @include ie {
                height: 9em;

                &:after {
                    height: 1.5em;
                }
            }
        }

        #{$this}__meta-tags {
            position: absolute;
            left: auto;
            bottom: 2.9rem;
            right: 3.2rem;
        }
    }

    // Podcast content
    &.card--podcast {
        #{$this}__meta-tags {
            position: absolute;
            left: auto;
            bottom: 2.9rem;
            right: 3.2rem;
        }

        .card__meta-tags {
            span {
                color: $black;
            }
        }
    }

    // Storytelling Story Film / General Card
    &.card--story-film,
    &.card--story-general {
        z-index: 10;

        color: $white;

        background-color: transparent;

        @include mq('medium', 'max') {
            #{$this}__inner {
                background-color: transparent;
            }

            #{$this}__content {
                padding: 4rem 0 !important;
            }

            #{$this}__meta-cta {
                right: 0 !important;
            }

            #{$this}__meta-tags {
                left: 0 !important;

                a.btn-link:hover {
                    background: transparent;
                }
            }

            /* Invert on Small Screen as white background is removed */
            a.btn-dark {
                color: $black !important;

                border-color: $white !important;

                background: $white !important;
            }
        }

        @include mq('medium', 'min') {
            &.card--story-general {
                color: $black;

                .card__content {
                    color: $black;
                }

                #{$this}__inner {
                    color: $black;

                    background-color: #f5f5f5;
                }

                #{$this}__meta-tags {
                    span {
                        color: $black;
                    }

                    a.btn-link:hover {
                        background: transparent;
                    }
                }
            }

            &.card--story-film {
                #{$this}__meta-tags {
                    a.btn-link:hover {
                        background: transparent;
                    }
                }
            }
        }

        .card__title {
            @include font-family('medium', '!important');
        }

        #{$this}__inner {
            background-color: rgba(0, 0, 0, 0.8);

            transform: unset !important;
        }

        #{$this}__blurb-wrap {
            opacity: 1;

            transition: unset;
        }

        #{$this}__meta-tags {
            span {
                color: $white;
            }
        }

        #{$this}__content-wrapper {
            height: 100%;
        }
    }

    /* Fix Animations for PAW Cards */
    &.card--paw-event,
    &.card--paw-petition,
    &.card--paw-volunteer,
    &.card--paw-grantee {
        overflow: hidden;

        #{$this}__meta-secondary {
            padding-right: calc(var(--bs-gutter-x) * 0.5);

            transition: opacity 0.1s $ease-hover;
        }

        #{$this}__title {
            color: $white;

            span {
                position: relative;
            }
        }

        #{$this}__blurb-wrapper {
            height: auto;

            opacity: 0;

            transition: opacity 0.1s $ease-hover;
        }

        #{$this}__blurb {
            position: relative;
        }
    }

    // TODO: fix animation conflicts between this card and storytelling

    // PAW
    &.card--paw {
        cursor: pointer;

        #{$this}__inner {
            position: relative;

            padding-top: calc(480 / 360 * 100%);

            transition: transform 0.3s $ease-out-soft;
        }

        #{$this}__content {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-flow: row wrap;
            align-items: center;

            width: 100%;
            height: 100%;
            padding: 12rem 2.4rem;
            box-sizing: border-box;

            color: $black;

            background-color: transparent;

            @include mq('large', 'max') {
                padding: 8rem 2.4rem;
            }
        }

        #{$this}__image-wrap {
            padding-top: calc(168 / 412 * 100%);
        }

        #{$this}__title {
            @include title-2;
            display: -webkit-box;
            flex: 0 0 100%;

            max-width: none;
            padding-bottom: 0.15rem;

            text-align: left;
            text-overflow: ellipsis;

            overflow: hidden;

            transition: transform 0.3s $ease-out-soft;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            span {
                display: block;

                width: 100%;
            }
        }

        #{$this}__blurb-wrapper {
            flex: 0 0 100%;

            margin-top: 0;
        }

        &:not(.card--paw-event, .card--paw-petition, .card--paw-volunteer) {
            &:not(.card--paw-grantee) {
                #{$this}__blurb-wrapper {
                    @include mq('large', 'max') {
                        height: auto;

                        opacity: 1;
                    }
                }
            }
        }

        #{$this}__blurb {
            display: -webkit-box;

            text-overflow: ellipsis;

            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-clamp: 3;

            @include mq('large', 'max') {
                font-size: 1.2rem;
            }

            @include mq('xxxlarge') {
                @include p;
            }
        }

        #{$this}__meta-primary,
        #{$this}__meta-secondary {
            position: absolute;

            display: block;

            @include font-family('medium');
            text-align: center;
        }

        #{$this}__meta-primary {
            top: 50%;
            left: 50%;
            z-index: 2;

            opacity: 0;
            transform: scale(0.8) translate(-50%, -50%);
            transform-origin: left center;

            transition:
                transform 0.3s $ease-out-soft 0s,
                opacity 0.3s $ease-out-soft 0s;

            @include no-hover {
                opacity: 1;
                transform: none;
            }

            a {
                padding-right: 1.6rem;
                padding-left: 1.6rem;

                figure {
                    display: none;
                }
            }
        }

        #{$this}__meta-secondary {
            @include p;

            left: 2.4rem;
            bottom: 2.4rem;
            right: 2.4rem;

            display: -webkit-box;
            display: box;

            margin-bottom: 0;

            color: $black;
            text-align: left;

            overflow: hidden;

            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            span {
                display: block;

                margin-top: 0.4rem;
            }
        }

        #{$this}__badge {
            top: 2.4rem;
            left: 2.4rem;
            z-index: 1;

            text-transform: capitalize;

            opacity: 1;
            transform: none;
            transform-origin: left center;

            transition:
                transform 0.3s $ease-out-soft 0.1s,
                opacity 0.3s $ease-out-soft 0.1s;

            .badge {
                position: relative;
                inset: 0;
            }

            figure {
                display: none;
            }

            a {
                padding: 0 0.9rem;

                color: $white;
                line-height: 1.6rem;

                background-color: rgba($black, 0.3);
            }
        }

        #{$this}__arrow {
            top: 3.2rem;
            right: 3.2rem;

            width: 2.6rem;
            height: auto;

            opacity: 0.4;

            transition: transform $button-out-time $ease-hover;
        }
    }

    &.card--paw-event {
        #{$this}__inner {
            background-color: #003da5;
        }

        #{$this}__meta-secondary {
            color: $white;
        }
    }

    &.card--paw-grantee {
        #{$this}__inner {
            background-color: #500778;
        }

        #{$this}__meta-secondary {
            color: $white;
        }
    }

    &.card--paw-volunteer,
    &.card--paw-petition {
        #{$this}__blurb,
        #{$this}__title {
            color: $black;
        }

        #{$this}__badge .badge {
            color: $white;

            background-color: $black;
        }
    }

    &.card--paw-petition {
        #{$this}__inner {
            background-color: #32b67a;
        }
    }

    &.card--paw-volunteer {
        #{$this}__inner {
            background-color: #feb904;
        }
    }

    &.card--paw-generic {
        cursor: pointer;

        img {
            height: 50%;
        }

        #{$this}__logo-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 50%;

            svg {
                width: 50%;
                height: auto;
            }
        }

        #{$this}__image-overlay {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 50%;

            background-image: linear-gradient(-180deg, rgba(51, 51, 51, 0) 2%, #333 100%);

            opacity: 0;

            transition: opacity 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                opacity: 1;
            }
        }

        #{$this}__blurb-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 50%;
            margin: 0;
            padding: 2rem;

            opacity: 1;

            transition: transform 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                transform: translateY(-4.4rem);
            }
        }

        #{$this}__inner {
            position: relative;

            padding-top: calc(480 / 360 * 100%);
        }

        #{$this}__content {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            padding: 0;

            color: $black;
            text-align: center;

            background: $grey-dark;
        }

        #{$this}__meta-primary-wrapper {
            position: absolute;
            left: 50%;
            bottom: 0;

            transform: translateX(-50%);
        }

        #{$this}__meta-primary {
            opacity: 0;

            transition:
                opacity 0.3s $ease-out-soft,
                transform 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                opacity: 1;
                transform: translateY(-4rem);
            }
        }
    }

    // Review
    &.card--review {
        cursor: pointer;

        transition: transform $button-out-time $ease-hover;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            z-index: 0;

            width: 80%;
            height: 50%;

            background-color: transparent;

            box-shadow: 0 $spacer ($spacer * 4) 0 rgba($black, 0.3);

            opacity: 0;
            transform: translateX(-50%);

            transition: opacity 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                display: none;
            }
        }

        p {
            margin: 0 auto 2rem;
            padding-top: 1rem;

            font-weight: $font-weight-medium;
        }

        span,
        h6 {
            font-size: 1.3rem;
        }

        span {
            font-weight: $font-weight-medium;
        }

        #{$this}__inner {
            position: relative;
            z-index: 1;

            padding-top: calc(336 / 336 * 100%);

            border-radius: $border-radius-sm;

            transition: transform $button-out-time $ease-hover;

            &,
            section .themed.is-light & {
                background-color: $white;
            }

            .is-dark &,
            section .themed.is-dark & {
                background-color: $grey-darkest;
            }
        }

        #{$this}__content {
            position: absolute;
            top: 50%;
            left: 0;

            color: $white;
            text-align: center;

            transform: translateY(-50%);

            // rework card, bug when theres not enough text
            // width: 100%;
        }
    }

    // feature

    &.card--feature {
        #{$this}__media-wrapper {
            position: relative;

            padding-top: calc(300 / 413 * 100%);

            @include mq('large') {
                width: 100%;
            }

            &#{$this}__media-wrapper__caption-show {
                #{$this}__caption {
                    opacity: 1;

                    pointer-events: auto;
                }

                svg.icon-ellipses {
                    opacity: 0;
                }

                svg.icon-x {
                    opacity: 1;
                }
            }
        }

        #{$this}__image,
        #{$this}__video {
            @include is-absolute-fs;
        }

        #{$this}__image {
            img {
                @include is-absolute-fs;
                @include object-fit(cover);
            }
        }

        #{$this}__video {
            left: -1px;

            padding: 0 1px;

            background: $white;

            video {
                width: 102%;
                height: 102%;
            }
        }

        #{$this}__caption {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;

            border-radius: 0.8rem;

            opacity: 0;

            overflow: hidden;

            pointer-events: none;

            transition: opacity 0.3s $ease-fade;

            @include mq('large') {
                padding: 3rem 2.4rem;
            }

            &--cta {
                position: absolute;
                bottom: 3rem;
                right: 4rem;
                z-index: 2;

                width: 4rem;
                height: 4rem;

                border-radius: 4rem;

                background: $white;

                svg {
                    &.icon-x {
                        opacity: 0;
                    }
                }

                @include has-hover {
                    &:hover {
                        + #{$this}__caption {
                            opacity: 1;

                            pointer-events: auto;
                        }
                    }
                }
            }

            &--background {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background-color: $black;

                opacity: 0.5;
            }

            &--content {
                position: absolute;
                top: 4rem;
                left: 4rem;

                width: calc(100% - 4.8rem);

                color: $white;

                @include mq('small') {
                    top: auto;
                    left: 4rem;
                    bottom: 4.4rem;

                    width: 66.879%;
                    max-width: 42rem;
                    margin-right: 6rem;
                }
            }

            h6 {
                margin-bottom: 1.4rem;
            }

            p {
                font-weight: $font-weight-medium;
                font-size: 1.6rem;
                line-height: 2.4rem;

                + h6 {
                    margin-top: 4.2rem;
                }
            }
        }

        #{$this}__caption-mult-title {
            &:first-child {
                margin-top: 2.8rem;
            }
        }

        #{$this}__badge {
            @include font-family('medium');
            position: absolute;
            inset: 2.1rem 2.4rem auto auto;

            display: inline-block;

            width: auto;
            padding: 0.8rem 1.125rem;

            font-size: 1rem;
            line-height: 1;

            border-radius: 2.2rem;

            background-color: $white;
        }

        #{$this}__play-cta {
            position: absolute;
            bottom: 4rem;
            right: 4rem;
            z-index: 2;

            padding: 0.5rem;

            border-radius: 3rem;

            background-color: $white;

            cursor: pointer;

            &--button {
                bottom: 3rem;
                right: 4rem;

                float: left;

                width: 4rem;
                height: 4rem;
                padding-top: 1.2rem;
                padding-left: 1.4rem;

                border-radius: 4rem;

                background-color: $white;
            }

            &--text {
                float: left;

                margin: 0.6rem 2rem 0 -0.5rem;

                font-weight: $font-weight-medium;
                font-size: 1.6rem;
                letter-spacing: -0.16px;
            }

            &--link {
                background-color: $white;

                .icon {
                    svg {
                        width: 1.4rem;
                        height: 1.4rem;
                    }
                }
            }

            figure {
                transition: transform $button-out-time $ease-hover;
            }

            &:hover {
                figure {
                    transform: scale(1.05);

                    transition: transform $button-out-time $ease-hover;
                }
            }
        }
    }

    // image

    &.card--image {
        // background colors added to support fallbacks
        &.background-red {
            .card__image {
                background-color: $red-brand;
            }
        }

        &.background-purple {
            .card__image {
                background-color: $purple-brand;
            }
        }

        &.background-blue {
            .card__image {
                background-color: $blue-brand;
            }
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            z-index: 0;

            width: 80%;
            height: 50%;

            background-color: transparent;

            box-shadow: 0 $spacer ($spacer * 4) 0 rgba($black, 0.5);

            opacity: 0;
            transform: translateX(-50%);

            transition: opacity 0.3s $ease-out-soft;

            @include mq('large', 'max') {
                display: none;
            }
        }

        #{$this}__inner {
            padding-top: 0;

            border-radius: $border-radius;

            background: $grey-white;

            overflow: hidden;

            transition: transform 0.3s $ease-out-soft;

            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                z-index: 0;

                width: 100%;
                height: 100%;

                border-radius: $border-radius;

                background: rgb(0, 0, 0);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);

                opacity: 0.8;

                transition: opacity 0.3s $ease-out-soft;
            }
        }

        #{$this}__block-link {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            display: block;

            width: 100%;
            height: 100%;

            text-indent: -9999px;
        }

        #{$this}__image {
            position: relative;

            width: 100%;

            // Fix Firefox bug
            max-height: 0;
            padding-top: 100%;

            border-radius: $border-radius;

            overflow: hidden;

            img {
                @include object-fit(cover);
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                transition:
                    transform 0.8s $ease-out-soft,
                    filter 0.4s,
                    filter 0.4s,
                    opacity 0.2s;

                // Add overlay if caption on image
                + #{$this}__caption {
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';

                        display: block;

                        width: 100%;
                        height: 100%;

                        background: rgba(0, 0, 0, 0.4);

                        transition: background-color 0.8s cubic-bezier(0.38, 0.41, 0.27, 1);
                    }
                }
            }
        }

        #{$this}__caption {
            position: absolute;
            top: 0;
            z-index: 1;

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        #{$this}__caption-heading {
            @include h4;

            display: block;

            margin: 0 auto 3rem;
            padding: 0 2rem;

            color: $white;
            text-align: center;

            transform: translateY(3.5rem);

            transition: transform 0.4s $ease-out-soft;

            @include mq('large') {
                @include h3;
            }

            @include mq('large', 'max') {
                transform: translateY(0);
            }
        }

        #{$this}__caption-btn {
            z-index: 2;

            transform: translateY(3.5rem);

            transition:
                opacity 0.6s $ease-out-soft,
                transform 0.4s $ease-out-soft;

            @include mq('large') {
                opacity: 0;
            }

            @include mq('large', 'max') {
                opacity: 1;
                transform: translateY(0);
            }

            .btn-lg {
                display: block;
            }

            .cta-rounded-rect__heading {
                width: auto;
            }
        }
    }

    // router (multi-link)

    &.card--router {
        &:before {
            display: none;
        }

        #{$this}__inner {
            padding-top: 0;
            padding-bottom: $spacer;

            border-radius: $border-radius;

            background: none;

            overflow: visible;
        }

        #{$this}__image {
            position: relative;

            // Fix Firefox bug
            max-height: 0;
            padding-top: 100%;

            border-radius: $border-radius;

            background-color: $grey-white;

            overflow: hidden;

            @include mq('medium', 'min') {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 632px;
                padding-top: min(632px, 100%);
            }
        }

        #{$this}__video-container {
            position: absolute;
            inset: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            video {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        #{$this}__caption {
            position: absolute;
            top: 0;
            z-index: 2;

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            pointer-events: none;
        }

        #{$this}__caption-heading {
            position: relative;

            display: block;

            padding: 0 2rem;

            color: $white;
            text-align: center;

            @include mq('large') {
                opacity: 0;
            }

            @include mq('large', 'max') {
                display: none;
            }

            figure {
                position: absolute;
                bottom: 0;
                right: 0;

                width: 1rem;
                height: 1rem;

                @include mq('medium', 'min') {
                    bottom: 2px;

                    width: 1.2rem;
                    height: 1.2rem;
                }

                svg {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 1rem;
                    height: 1rem;

                    @include mq('medium', 'min') {
                        width: 1.2rem;
                        height: 1.2rem;
                    }

                    * {
                        fill: $white !important;
                    }
                }
            }
        }

        #{$this}__block-link {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            display: block;

            width: 100%;
            height: 100%;

            text-indent: -9999px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';

                display: block;

                width: 100%;
                height: 100%;

                background: rgba($black, 0.4);

                opacity: 0;

                transition: opacity 0.3s $ease-out-soft;

                @include mq('large', 'max') {
                    display: none;
                }
            }
        }

        #{$this}__copy {
            position: relative;

            padding: $spacer * 2 0 $spacer * 3 0;

            background: none;

            .copy__eyebrow,
            .copy__title {
                margin-top: 0;
                margin-bottom: $spacer;
            }

            .copy__subtitle {
                margin-bottom: $spacer * 2;
            }

            .copy__body {
                margin: 0;

                svg {
                    height: 1em;
                }
            }
        }

        #{$this}__addon {
            position: relative;

            min-height: auto !important; // reset inline min-height
            padding-bottom: $spacer * 2;

            .addon__wrapper {
                display: inline-block;
            }

            .addon__link,
            .btn {
                margin: 0 1.6rem 0 0;
            }

            .cta-link-underline {
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 1.2rem;

                @include mq('medium', 'min') {
                    font-size: 1.4rem;
                }
            }

            .addon__link {
                @include underline-styles;
                position: relative;

                font-weight: 800;
                font-size: 1.2rem;
                font-style: normal;
                line-height: 42px;
                white-space: nowrap;
                text-decoration-line: underline;

                @include mq('medium', 'min') {
                    font-size: 1.4rem;
                }

                figure {
                    position: absolute;
                    bottom: 0;
                    right: -1.5rem;

                    width: 1rem;
                    height: 1rem;

                    @include mq('medium', 'min') {
                        bottom: 2px;
                        right: -2rem;

                        width: 1.2rem;
                        height: 1.2rem;
                    }

                    svg {
                        position: absolute;
                        top: 0;
                        left: 0;

                        width: 1rem;
                        height: 1rem;

                        @include mq('medium', 'min') {
                            width: 1.2rem;
                            height: 1.2rem;
                        }
                    }
                }

                &.has-icon {
                    margin-right: 3.2rem;
                }
            }
        }

        // Background settings
        &.card--bg-no-bg {
            #{$this}__copy,
            #{$this}__addon {
                padding-right: $spacer;
                padding-left: $spacer;
            }
        }

        &.card--bg-fill {
            #{$this}__copy,
            #{$this}__addon {
                padding-right: $spacer * 2;
                padding-left: $spacer * 2;
            }

            #{$this}__image {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            #{$this}__inner {
                background: $white-concrete;
            }
        }

        // Content size settings
        &.card--content-size-s {
            .copy__eyebrow {
                @include p-xs;
            }

            .copy__title {
                @include title-1;
            }

            .copy__subtitle,
            .copy__body,
            .card__caption-heading {
                @include p-s;
            }

            .copy__subtitle,
            .card__caption-heading {
                font-weight: $font-weight-medium;
            }
        }

        &.card--content-size-m {
            .copy__eyebrow {
                @include p-s;
            }

            .copy__title {
                @include display-4;
            }

            .copy__subtitle,
            .copy__body,
            .card__caption-heading {
                @include p-m;
            }

            .copy__subtitle,
            .card__caption-heading {
                font-weight: $font-weight-medium;
            }
        }
    }

    // chip

    &.card--chip {
        @include mq('xlarge', 'max') {
            &[data-skrollex] {
                opacity: 1;
                transform: none;
            }
        }

        #{$this}__ratio {
            padding-bottom: math.percentage(math.div(136, 200)); /* Minimum aspect ratio */
        }

        &:before {
            top: 10%;

            width: 80%;
            height: 80%;
        }

        #{$this}__inner {
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;

            padding: 0;

            border: 1px solid #efefef;
            border-radius: $border-radius;

            #{$this}__content {
                display: flex;
                flex-flow: column;
                justify-content: center;

                width: 100%;
                height: 100%;
                padding: $spacer * 2;
            }

            &,
            section .themed.is-light & {
                border-color: #efefef;

                background-color: $grey-white;
                #{$this}__content {
                    color: var(--pat-theme-fore, $black);
                }
            }

            .is-dark &,
            section .themed.is-dark & {
                border-color: $darker-gray;

                background-color: $grey-darkest;
                #{$this}__content {
                    color: var(--pat-theme-fore, $white);
                }
            }
        }

        #{$this}__title {
            @include title-3;

            width: 100%;
            padding: 0;

            text-align: center;
        }
    }

    // FPC PDP card containing orb/blob (based on --article-content styles)
    &.card--fpc-material-content {
        position: relative;

        @include mq('medium', 'min') {
            &.is-hover {
                &:hover {
                    #{$this}__content,
                    #{$this}__meta-tags span {
                        color: $white;
                    }

                    #{$this}__content-wrapper {
                        &:after {
                            display: none;
                        }
                    }

                    #{$this}__title,
                    #{$this}__blob,
                    #{$this}__description {
                        opacity: 0;
                    }

                    #{$this}__title {
                        transition: none;
                    }

                    #{$this}__blob {
                        transition: opacity 0.15s $ease-out-soft 0s;
                    }

                    #{$this}__background {
                        opacity: 1;

                        transition: opacity 0.5s $ease-out-soft;
                    }

                    #{$this}__meta-tags {
                        opacity: 0;
                    }

                    #{$this}__background-blurb {
                        opacity: 1;
                        transform: none;

                        transition:
                            transform 0.3s $ease-out-soft,
                            opacity 0.3s $ease-out-soft;
                    }
                }
            }
        }

        &:nth-child(5n + 1),
        &:nth-child(5n + 3) {
            #{$this}__blob {
                .blob {
                    top: -30%;
                    right: -10%;
                }

                [data-blob]:nth-child(1) {
                    right: 5%;
                }
            }
        }

        &:nth-child(5n + 1) {
            #{$this}__blob {
                [data-blob]:nth-child(1) {
                    right: -5%;
                }
            }
        }

        &:nth-child(5n + 2),
        &:nth-child(5n + 4) {
            #{$this}__blob {
                .blob {
                    top: -30%;
                    left: 5%;
                }

                [data-blob]:nth-child(1) {
                    left: 5%;
                }
            }
        }

        &:nth-child(5n + 2) {
            #{$this}__blob {
                [data-blob]:nth-child(1) {
                    left: 0;
                }
            }
        }

        &:nth-child(5n + 3),
        &:nth-child(5n + 5) {
            #{$this}__blob {
                .blob {
                    top: -30%;
                    left: 0;
                }

                [data-blob]:nth-child(1) {
                    left: 0;
                }
            }
        }

        &:nth-child(5n + 4) {
            #{$this}__blob {
                .blob {
                    top: -30%;
                    left: 0;
                }

                [data-blob]:nth-child(1) {
                    left: 0;
                }
            }
        }

        #{$this}__content-wrapper {
            p {
                line-height: 1.5em;
            }
        }

        #{$this}__link-full {
            z-index: 3;
        }

        #{$this}__title {
            @include h5;

            margin-bottom: 0.8rem;

            @include mq('large') {
                @include h6;
            }
        }

        #{$this}__description {
            display: -webkit-box;
            display: box;

            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        #{$this}__blob {
            position: absolute;
            top: 0;
            left: calc(var(--bs-gutter-x) * 0.5);
            z-index: 2;

            width: calc(100% - (var(--bs-gutter-x) * 0.5));
            height: 60%;

            border-radius: $border-radius;

            overflow: hidden;

            transition: opacity 0.3s $ease-out-soft 0.15s;

            @include mq('xlarge') {
                left: 0;

                width: 100%;
            }

            .blob,
            [data-blob] {
                position: absolute;
                top: 0;
                right: 0;

                width: 100%;
                height: 100%;
            }
        }

        #{$this}__background {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            border-radius: $border-radius;

            opacity: 0;

            overflow: hidden;

            transition: opacity 0.15s $ease-out-soft;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                z-index: 1;

                width: 100%;
                height: 100%;

                background: $black;

                opacity: 0.5;
            }

            img {
                display: block;

                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        #{$this}__background-blurb {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            width: 100%;
            height: 100%;
            padding: 3.2rem;
            box-sizing: border-box;

            color: $white;
            font-size: map.get($body-desktop-sizes, 'p');
            line-height: 1.5em;

            opacity: 0;
            transform: translateY(3rem);

            transition: opacity 0s $ease-out-soft;

            b {
                display: block;

                margin-top: 3.8rem;
            }
        }

        #{$this}__meta-tags {
            z-index: 2;

            font-size: 1.2rem;

            span {
                color: $black;

                transition: color 0.3s $ease-out-soft;
            }
        }

        #{$this}__meta-cta.btn-light {
            background-color: $white-alabaster;
        }
    }

    // FPC PDP card containing facility (based on --article-content styles)
    &.card--fpc-facility-content {
        #{$this}__content-wrapper:after {
            display: none;
        }

        #{$this}__meta-tags {
            font-size: 1.2rem;

            span {
                color: $black;

                transition: color 0.3s $ease-out-soft;
            }
        }
    }

    &.card--view-all {
        a {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;

            display: inline-block;

            padding: 1rem;

            line-height: 1;
            text-align: center;
            text-decoration: none;

            transform: translate(-50%, -50%);

            &,
            section .themed.is-light & {
                color: $black;
            }

            .is-dark &,
            section .themed.is-dark & {
                color: $white;
            }

            span {
                font-weight: $font-weight-medium;
                font-size: 1.2rem;
                white-space: nowrap;

                @include mq('small') {
                    font-size: 1.6rem;
                }
            }

            &:hover {
                &:after {
                    opacity: 0;
                }
            }

            &:after {
                position: absolute;
                left: 1rem;
                bottom: 1rem;
                content: '';

                width: calc(100% - 2rem);
                height: 1px;

                transition: opacity 0.2s $ease-out-soft;

                &,
                section .themed.is-light & {
                    background: $black;
                }

                .is-dark &,
                section .themed.is-dark & {
                    background: $white;
                }
            }

            &.card__link-full:after {
                display: none;
            }
        }
    }

    // size alternatives

    &.card--lg {
        #{$this}__image {
            padding-top: calc(480 / 720 * 100%);
        }
    }
}

.card {
    &--default {
        .card__image-wrapper {
            position: relative;

            &:has([data-srcset]) {
                overflow: hidden;
            }
        }
    }

    &__magic-play-btn {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        &-img-wrap {
            position: relative;

            .card__image-wrapper & {
                position: static;
            }
        }

        svg * {
            fill: $black;
        }

        .cta-circle {
            background: $white;
        }
    }
}

// All hover styles, for non-touch devices

@include has-hover {
    .card {
        a .card-img-top {
            transition: transform 0.3s $ease-out-soft;
        }

        &:hover,
        &.has-hover {
            a {
                .card-img-top {
                    transform: scale(1.02);
                }

                .card__magic-play-btn {
                    transform: translate(-50%, -50%) scale(1.02);
                }
            }
        }
    }

    .card-tile {
        @include mq('medium', 'min') {
            &:hover,
            &.has-hover {
                &:before {
                    opacity: 1;
                }

                .card__inner {
                    transform: scale(1.02);
                }

                .card__svg {
                    opacity: 0;

                    + .card__content-wrapper .card__title {
                        opacity: 1;
                    }
                }

                .card__svg + .card__content-wrapper .card__content {
                    opacity: 1;
                }

                .card__meta-cta.btn-outlined {
                    color: var(--bs-btn-color);

                    border-color: var(--bs-btn-bg);

                    background-color: var(--bs-btn-bg);

                    transition:
                        transform $button-out-time $ease-hover,
                        color $button-out-time $ease-hover,
                        background-color $button-out-time $ease-hover;
                }
            }
        }

        &.is-hover {
            &:hover,
            &.has-hover {
                .card__content-wrapper {
                    height: 100%;
                }

                .card__blurb-wrap {
                    opacity: 1;
                }
            }
        }

        .card__svg + .card__content-wrapper .card__content {
            opacity: 0;

            transition: opacity 0.3s $ease-out-soft;
        }

        &.card--content {
            &:hover,
            &.has-hover {
                .card__meta-primary {
                    opacity: 1;
                    transform: scale(1);

                    transition:
                        transform 0.3s $ease-out-soft 0.1s,
                        opacity 0.3s $ease-out-soft 0.1s;
                }

                .card__badge {
                    opacity: 0;
                    transform: scale(1.1);

                    transition:
                        transform 0.3s $ease-out-soft 0s,
                        opacity 0.3s $ease-out-soft 0s;
                }

                .card__inner {
                    transform: scale(1.02);

                    @include mq('large', 'max') {
                        transform: none;
                    }
                }

                .card__arrow {
                    transform: scale(1.125);
                }

                .card__title {
                    transform: translateY(-1rem);

                    @include mq('large', 'max') {
                        transform: translateY(50%);
                    }
                }

                .card__image-wrapper {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &.card--manual-content {
            &:hover,
            &.has-hover {
                .card__image-wrapper:after {
                    opacity: 1;
                }
            }
        }

        &.card--article-content {
            @include mq('medium', 'min') {
                &:hover,
                &.has-hover {
                    .card__content-wrapper {
                        top: 0;

                        height: 100%;
                    }

                    .card__image-wrapper,
                    .card__blurb-ellipsis,
                    .card__blurb {
                        opacity: 0;
                    }

                    .card__blurb {
                        opacity: 1;
                    }

                    .card__meta-cta {
                        &.btn-light {
                            color: $white;

                            background-color: $black;
                        }

                        &.btn-dark {
                            color: $black;

                            background-color: $white;
                        }
                    }
                }
            }

            .card__content-wrapper {
                top: $image-top-placeholder;

                height: $calc-height-minus-placeholder;

                transition:
                    height 0.3s $ease-out-soft,
                    top 0.3s $ease-out-soft;
            }
        }

        &.card--fpc-facility-content {
            &:hover,
            &.has-hover {
                .card__image-wrapper,
                .card__blurb-ellipsis,
                .card__blurb-short {
                    opacity: 1;
                }

                .card__content-wrapper {
                    top: 33%;

                    height: 67%;
                }
            }
        }

        &.card--film-content {
            @include mq('medium', 'min') {
                &:hover,
                &.has-hover {
                    .card__blurb-wrap {
                        opacity: 1;
                    }

                    .card__inner:after {
                        background-position: 0% 100%;
                    }
                }
            }
        }

        &.card--paw-event,
        &.card--paw-petition,
        &.card--paw-volunteer,
        &.card--paw-grantee {
            /* Support PAW Hover States */
            &:hover,
            &.has-hover {
                .card__title {
                    transform: translateY(-17.5%);
                }

                .card__blurb-wrapper {
                    opacity: 1;
                }

                .card__meta-secondary {
                    opacity: 0;
                }
            }
        }

        &.card--paw {
            &:hover,
            &.has-hover {
                .card__meta-primary {
                    opacity: 1;
                    transform: scale(1.1) translate(-50%, -50%);
                }

                .card__title,
                .card__badge {
                    opacity: 0;
                }

                &:not(.card--paw-event, .card--paw-petition) {
                    &:not(.card--paw-volunteer, .card--paw-grantee) {
                        .card__title {
                            @include mq('large', 'max') {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }

        &.card--paw-generic {
            &:hover,
            &.has-hover {
                .card__blurb-wrapper {
                    transform: translateY(-4.4rem);
                }

                .card__meta-primary {
                    opacity: 1;
                    transform: translateY(-4rem);
                }

                .card__image-overlay {
                    opacity: 1;
                }
            }
        }

        &.card--review {
            &:hover,
            &.has-hover {
                @include mq('large') {
                    .card__inner {
                        transform: scale(1.05);

                        transition: transform $button-out-time $ease-hover;
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &.card--feature {
            &:hover,
            &.has-hover {
                img {
                    transform: none;
                }
            }
        }

        &.card--image {
            @include mq('xlarge', 'max') {
                &[data-skrollex] {
                    opacity: 1;
                    transform: none;
                }
            }

            &:hover,
            &.has-hover {
                cursor: pointer;

                .card__caption-heading {
                    transform: translateY(0);
                }

                .card__caption-btn {
                    opacity: 1;
                    transform: translateY(0);
                }

                &:before {
                    opacity: 1;
                }

                .card__image img + .card__caption::before {
                    background: linear-gradient(
                        180deg,
                        rgba(244, 245, 245, 0) 30%,
                        rgba(0, 0, 0, 0.1142) 100%
                    );
                }

                .card__inner {
                    transform: scale(1.02) !important;

                    overflow: visible;

                    @include mq('large', 'max') {
                        transform: none !important;
                    }
                }
            }
        }

        &.card--router {
            cursor: default;

            @include mq('xlarge', 'max') {
                &[data-skrollex] {
                    opacity: 1;
                    transform: none;
                }
            }

            &:before {
                opacity: 0;
            }

            .card__inner {
                transform: none;
            }

            .card__block-link {
                &:hover:before {
                    opacity: 1;
                }

                &:hover ~ .card__caption {
                    .card__caption-heading {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .layout-wide {
        .card-tile {
            @include mq('medium', 'min') {
                &:hover,
                &.has-hover {
                    @include mq('large', 'min') {
                        .card__image-wrapper {
                            opacity: 1;
                        }
                    }

                    &.card--manual-content,
                    &.card--film-content {
                        .card__content-wrapper .card__content {
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            @include mq('medium', 'min') {
                &.is-branded:hover .card__svg + .card__content-wrapper .card__content {
                    opacity: 1;
                    transform: translateY(-50%) translateX(-50%);
                }
            }

            &.is-branded {
                .card__svg + .card__content-wrapper .card__content {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    width: 60%;

                    text-align: center;

                    opacity: 0;
                    transform: translateY(-30%) translateX(-50%);

                    transition:
                        opacity 0.3s $ease-out-soft,
                        transform 0.3s $ease-out-soft;
                }
            }

            .card__content-wrapper {
                height: 100%;
                box-sizing: border-box;
            }
        }

        .card-tile.card--manual-content,
        .card-tile.card--film-content {
            &.col-md-4 {
                @include mq('medium') {
                    flex: 0 0 50%;

                    max-width: 50%;
                }
            }

            .card__content-wrapper .card__content {
                position: absolute;
                top: 50%;
                left: 0;

                width: 100%;

                transform: translateY(-3.75em);

                transition: transform 0.3s $ease-out-soft;

                @include mq('medium') {
                    width: 100%;
                }

                @include mq('large') {
                    width: 70%;
                }

                @include mq('xlarge') {
                    width: 50%;
                }
            }
        }
    }
}

// Touch devices only styles

@include no-hover {
    .card-tile {
        &:before {
            display: none;
        }

        // Baseline Content Card

        &.card--card-content {
            .card__meta-primary {
                opacity: 1;
                transform: none;
            }

            .card__title {
                transform: translateY(50%);
            }

            .card__image-wrapper {
                &:after {
                    opacity: 1;
                }
            }

            .card__blurb-wrapper {
                position: relative;
                bottom: 0;

                display: block;

                height: auto !important;
                margin-top: 0.75rem;

                opacity: 1;
            }
        }

        // PAW
        &.card--paw {
            .card__meta-primary {
                top: 2.4rem;
                left: 2.4rem;

                opacity: 1;
                transform: none !important;
            }

            .card__badge {
                display: none;
            }
        }

        &.card--paw-generic {
            .card__image-overlay {
                opacity: 1;
            }

            .card__blurb-wrapper {
                transform: translateY(-4.4rem);
            }

            .card__meta-primary {
                opacity: 1;
                transform: translateY(-4rem);
            }
        }

        // image

        &.card--image {
            /* &:hover {
                .card__inner {
                    transform: none !important;
                }
            } */

            .card__caption-heading {
                transform: translateY(0);
            }

            .card__caption-btn {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}

/**** 2 Up layout ****/

@include mq('medium') {
    .layout-wide .card--manual-content {
        .card__blurb {
            -webkit-line-clamp: 6;

            // IE
            @include ie {
                height: 9em;
            }
        }
    }
}

@include mq('large') {
    .layout-wide {
        .card__inner {
            padding-top: calc(480 / 720 * 100%);
        }

        .card-tile.card--image {
            .card__inner {
                padding-top: calc(480 / 720 * 100%);
            }

            .card__image {
                position: absolute;

                max-height: 100%;
                padding-top: 0;
            }
        }

        .card--manual-content {
            .card__blurb {
                // IE fallback for truncated text
                @include ie {
                    height: 4.5em;
                }

                // -webkit-line-clamp: 3;
            }
        }

        .card--article-content {
            .card__image-wrapper {
                position: absolute;
                top: 0;
                right: 0;

                width: 50%;
                height: 100%;
            }

            .card__image {
                height: 100%;

                + .ls-blur-up-img {
                    height: 100%;
                }
            }

            .card__content-wrapper {
                position: absolute;
                top: 0;
                left: 0;

                width: 50%;
                height: 100%;
            }
        }
    }
}

@include mq('xlarge') {
    .layout-wide .card--manual-content {
        .card__blurb {
            -webkit-line-clamp: 5;

            // IE fallback for truncated text
            @include ie {
                height: 7.5em;
            }
        }
    }
}

/**** Microsoft Edge Specific Scaling ****/

@supports (-ms-ime-align: auto) {
    body:not(.is-user-touch) .card-tile {
        &:hover .card__inner {
            transform: scale(1);
        }

        .card__inner {
            transform: scale(0.98);
        }
    }
}

// Podcast meta copy color change for wide layout
.layout-wide {
    .card-tile.card--podcast {
        .card__meta-tags {
            span {
                color: $white;
            }
        }
    }
}
