@use 'sass:math';

@mixin custom-input-required-span-styles {
    &:after {
        content: ' *';

        color: currentColor;
    }

    &.char-allowed-count:after,
    &.char-remain-count:after {
        content: '';
    }
}

.custom-input {
    position: relative;

    width: 100%;
    margin: 2.2rem 0 5rem;

    &.required,
    &[required] {
        label span,
        .custom-input__select-button.open em {
            @include custom-input-required-span-styles;
        }
    }

    input,
    select,
    textarea {
        @include custom-input-input;
        @include custom-input-input-read-only;
        z-index: 2;

        width: 100%;

        &:focus::placeholder {
            color: $grey-tundora;
        }

        [required] ~ span:not(.char-remain-count, .char-allowed-count) {
            @include custom-input-required-span-styles;
        }
    }

    select,
    input {
        border-bottom: 2px solid $black;
        border-radius: 0;

        &:-webkit-autofill ~ span:not(.char-remain-count, .char-allowed-count),
        &:required:valid ~ span:not(.char-remain-count, .char-allowed-count),
        &:focus ~ span:not(.char-remain-count, .char-allowed-count),
        &.input--clicked ~ span:not(.char-remain-count, .char-allowed-count) {
            top: -2.5rem;

            @include p-xs;
        }
    }

    .input__caption {
        @include font-family('medium');
        position: absolute;
        top: 3.4rem;
        left: 0;

        width: 100%;
        height: 30px;
        margin-top: 0.25rem;

        color: $grey-tundora;
        font-size: 1.2rem;
        line-height: 1.2em;
        white-space: break-spaces;
        text-align: left;
    }

    /* Initial Page Load Styling to prevent rendering glitch when JS swaps this out with Custom Select */
    select {
        z-index: 2;

        width: 100%;
        padding: 0;
        padding-right: $form-select-padding-x;
        -moz-padding-start: subtract(
            $form-select-padding-x,
            3px
        ); // See https://github.com/twbs/bootstrap/issues/32636

        color: $black;
        font-size: 1.6rem;

        border-bottom: 2px solid $black;
        border-radius: 0;

        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-position: $form-select-bg-position;
        background-size: $form-select-bg-size;

        box-shadow: 0 0 0 0 $black;

        appearance: none;

        transition: all 0.2s;
        caret-color: $black;

        &:not([multiple]),
        &[size='1'] {
            height: 30px;
        }
    }

    label:not(.had-focus, .has-focus, .has-value) {
        select:not([multiple], :focus) {
            color: transparent;
        }
    }

    &.has-value,
    .has-value {
        select {
            color: $black;
        }
    }

    textarea {
        min-height: 170px;
        padding: var(--bs-gutter-x);

        border: 2px solid $black;
        border-radius: $border-radius;
    }

    label {
        position: relative;

        display: inline-block;

        width: 100%;
        margin: 0;
        padding: 0;

        color: $grey-tundora;

        cursor: default;

        &.is-disabled {
            opacity: 0.5;

            pointer-events: none;
        }

        span:not(.char-remain-count, .char-allowed-count, .radio-inventory-msg) {
            @include font-family('regular');
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            max-width: 100%;
            min-height: 30px;

            font-size: 1.6rem;
            line-height: $line-height;
            white-space: nowrap;
            text-overflow: ellipsis;

            opacity: 1;

            overflow: hidden;

            pointer-events: none;

            transition: all 0.2s;
            -webkit-font-smoothing: antialiased;
        }

        select {
            @at-root .had-focus.custom-input select,
                &:focus {
                ~ span:not(.char-remain-count, .char-allowed-count, .full-label) {
                    top: -2.5rem;
                    left: 0;

                    @include p-xs;
                }
            }

            &.hidden {
                position: absolute;

                width: 0;
                height: 0;

                cursor: pointer;

                opacity: 0;
            }

            &[multiple],
            &[size]:not([size='1']) {
                padding-right: $form-select-padding-x;

                background-image: none;
            }
        }

        textarea {
            ~ span:not(.char-remain-count, .char-allowed-count, .radio-inventory-msg) {
                top: calc(var(--bs-gutter-x) + $input-border-width);
                left: var(--bs-gutter-x);

                max-width: calc(100% - (var(--bs-gutter-x) * 2));

                white-space: normal;

                transition: 0.1s;
            }

            &:not(:placeholder-shown) ~ span:not(.char-remain-count, .char-allowed-count),
            &:focus ~ span:not(.char-remain-count, .char-allowed-count),
            &.input--clicked ~ span:not(.char-remain-count, .char-allowed-count) {
                @include font-family('regular');
                top: -2.5rem;
                left: 0;

                font-size: 1.2rem;
            }
        }

        // IE Support via JS Classes
        &.has-focus,
        &.has-value {
            span:not(.char-remain-count, .char-allowed-count, .radio-inventory-msg) {
                @include font-family('regular');
                top: -2.5rem;

                font-size: 1.2rem;
            }

            &.is-textarea {
                span:not(.char-remain-count, .char-allowed-count) {
                    top: -2.5rem;
                    left: 0;
                }
            }
        }
    }

    &.custom-select:not(.has-value, .had-focus) {
        select:not([multiple]) {
            ~ .custom-input__select-button:not(.open) em {
                opacity: 0;
            }
        }
    }

    &.pristine-is-invalid.had-focus:not(.custom-select) select,
    &.pristine-is-invalid.had-focus .custom-input__select-button {
        border-bottom: 2px solid $red-utility;

        &:not(.open) {
            background-image: var(--chevron-down-small-invalid);

            em {
                color: $red-utility;
            }
        }

        &:not(.open):focus {
            box-shadow: 0 2px 0 0 $red-utility;
        }

        &.open,
        &.open:focus {
            border-bottom: 2px solid transparent;
        }
    }

    &.input-wrapper {
        label:not(.had-focus, .has-value, .has-focus) select {
            color: transparent;
        }

        .has-value {
            select {
                color: $black;
            }
        }

        select:focus {
            color: $black;
        }
    }

    &__select-button {
        @include custom-input-select-btn;
        position: relative;

        font-weight: 500;

        &.open,
        &.open:focus {
            top: -20px;

            border-bottom-color: transparent;
        }

        em {
            white-space: nowrap;
            text-overflow: ellipsis;

            background-repeat: no-repeat;
            background-position: $form-select-bg-position;
            background-size: $form-select-bg-size;

            overflow: hidden;
        }
    }

    &__select {
        @include custom-input-select;
        position: absolute;
        top: 10px;

        display: none;

        padding-top: 20px;

        &.open {
            display: block;
        }
    }

    &.is-disabled {
        button {
            color: $grey5;

            border-color: transparent;

            background: $grey2;

            cursor: not-allowed;
        }
    }

    &__group {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        label {
            @include font-family('medium');

            width: 49%;
            margin-bottom: 8px;
            padding: 12px 44px 12px 12px;

            color: $black;
            font-size: 1.2rem;
            line-height: 1.5;

            border: 2px solid $grey-white;
            border-radius: $border-radius;

            background-color: $grey-white;

            cursor: pointer;

            user-select: none;

            transition: all 0.2s;

            @include mq('medium') {
                width: 32%;
            }

            @include mq('large') {
                width: 23%;
            }

            @include mq('medium', 'max') {
                &.col-2-md {
                    width: 49%;
                }

                &.col-3-md {
                    width: 32%;
                }

                &.col-4-md {
                    width: 23%;
                }

                &.col-5-md {
                    width: 19%;
                }

                &.col-6-md {
                    width: 15%;
                }
            }

            @include mq('tiny', 'max') {
                &.col-2-sm {
                    width: 49%;
                }

                &.col-3-sm {
                    width: 32%;
                }

                &.col-4-sm {
                    width: 23%;
                }

                &.col-5-sm {
                    width: 19%;
                }

                &.col-6-sm {
                    width: 15%;
                }
            }

            &.col-2 {
                flex: unset;

                width: 49%;
                max-width: unset;
                -webkit-box-flex: unset;
            }

            &.col-3 {
                flex: unset;

                width: 32%;
                max-width: unset;
                -webkit-box-flex: unset;
            }

            &.col-4 {
                flex: unset;

                width: 23%;
                max-width: unset;
                -webkit-box-flex: unset;
            }

            &.col-5 {
                flex: unset;

                width: 19%;
                max-width: unset;
                -webkit-box-flex: unset;
            }

            &.col-6 {
                flex: unset;

                width: 15%;
                max-width: unset;
                -webkit-box-flex: unset;
            }

            &:after {
                position: absolute;
                top: 12px;
                right: 19px;
                content: '';

                width: 10px;
                height: 16px;

                border: 4px solid $green-utility;
                border-width: 0 4px 4px 0;

                opacity: 0;
                transform: rotate(45deg);

                transition: opacity 0.25s ease;
            }

            &:active {
                box-shadow:
                    0 4px 5px rgba(0, 0, 0, 0.14),
                    0 1px 10px rgba(0, 0, 0, 0.12),
                    0 2px 4px -1px rgba(0, 0, 0, 0.2);
            }

            &:focus-visible,
            &:focus-within,
            &.has-focus {
                outline: $blue-utility solid 4px;
            }

            &.is-checked {
                border-color: $black;

                &:after {
                    opacity: 1;

                    animation: check-appear 0.25s ease-in;

                    @keyframes check-appear {
                        from {
                            top: 18px;
                        }

                        to {
                            top: 12px;
                        }
                    }
                }
            }

            &.is-disabled {
                color: $grey-tundora;

                border-color: $grey-white;

                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25%3b height: 100%25%3b'%3e%3cline x1='0' y1='100%25' x2='100%25' y2='0' style='stroke:%23999%3bstroke-width:1'/%3e%3c/svg%3e");

                cursor: not-allowed;

                opacity: 1;

                &.is-checked {
                    border-color: $grey5;

                    &:after {
                        border-color: $grey5;
                    }
                }

                .radio-oos-label {
                    display: block;
                }
            }

            &.is-radio-tile,
            &.is-radio-tile-lg {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                min-height: 5.6rem;
                margin: math.div($spacer, 2);
                padding: $spacer * 0.5;

                &::after {
                    display: none;
                }

                .radio-inventory-msg {
                    opacity: 1;

                    &--waitlist,
                    &--backordered,
                    &--oos {
                        display: none;
                    }

                    &.is-waitlist {
                        .radio-inventory-msg--waitlist {
                            display: block;
                        }
                    }

                    &.is-oos {
                        .radio-inventory-msg--oos {
                            display: block;
                        }
                    }
                }

                small {
                    text-align: center;
                }
            }

            &.is-radio-tile {
                width: 5.6rem;

                &-lg {
                    width: 8rem;
                }
            }

            input {
                position: absolute;

                width: 0;
                height: 0;

                cursor: pointer;

                opacity: 0;
            }

            strong {
                @include font-family('medium');
            }

            small {
                @include font-family('regular');
                display: block;

                margin-top: math.div($spacer, 2);

                color: $grey-tundora;
                font-size: 1.2rem;
                line-height: 1.5;
            }
        }
    }

    .invalid-feedback {
        position: absolute;
        left: 0;

        width: 100%;
        height: 30px;

        color: $red-utility;
        line-height: 1.2em;
        text-align: left;
    }

    .error {
        color: $red-utility;
    }

    input.error {
        border-color: $red-utility;

        &:focus {
            outline: 0.2rem $blue-utility auto;

            box-shadow: 0 2px 0 0 $red-utility;
        }
    }

    .form-text {
        @include font-family('medium');
        display: block;

        width: 100%;

        font-size: 1rem;
        line-height: 1.4rem;
    }

    .is-textarea {
        .invalid-feedback {
            top: 100%;
        }
    }

    &.jp-birthday-wrapper {
        display: block;

        label {
            padding-bottom: 1rem;
        }

        .one-third-select {
            &:first-of-type .custom-input__select {
                &.open {
                    padding: 20px 0.75rem;
                }
            }
        }
    }

    &.has-icons {
        select {
            padding-left: 2.5rem;
        }

        .icon__flag {
            margin-top: 0.1rem;
        }
    }
}

.dark-theme,
.is-dark {
    .custom-input {
        input,
        select {
            caret-color: $white;

            &:not(:focus) {
                border-color: $white;
            }

            &::placeholder {
                color: transparent;
            }

            &:focus::placeholder {
                color: $grey-light;
            }

            &:focus,
            &:active,
            &.input--clicked {
                border-color: $blue-utility;
            }
        }

        select {
            option {
                color: $black;
            }

            &[multiple] {
                option {
                    color: $white;
                }
            }
        }

        select:focus {
            color: $white;
        }

        input,
        textarea {
            color: $white;
        }

        textarea {
            caret-color: $white;

            &:not(:focus) {
                border-color: $white;
            }

            &::placeholder {
                color: transparent;
            }

            &:focus::placeholder {
                color: $white-concrete;
            }

            ~ span:not(.char-remain-count, .char-allowed-count) {
                color: $white-concrete;
            }
        }

        label {
            span:not(.char-remain-count, .char-allowed-count) {
                color: $white-concrete;
            }

            // IE Support via JS Classes
            &.has-focus,
            &.has-value {
                span:not(.char-remain-count, .char-allowed-count) {
                    @include font-family('medium');
                    top: -2.5rem;

                    font-size: 1.2rem;
                }

                &.is-textarea {
                    span:not(.char-remain-count, .char-allowed-count) {
                        top: -2.5rem;
                        left: 0;
                    }
                }
            }
        }

        &__select-button {
            @include custom-input-select-btn($white);

            background-image: var(--chevron-down-small-white);

            select:not(.has-value) ~ & {
                color: transparent;
            }
        }

        .has-value:not(.pristine-is-invalid) ~ &__select-button {
            em {
                color: $white;
            }
        }

        &__select {
            background: $white;

            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);

            &::after {
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 0) 100%
                );
            }

            ul {
                max-height: 300px;

                white-space: nowrap;

                // Internet Explorer Scroll Bar Styling
                scrollbar-base-color: $white;
                scrollbar-face-color: $black;
                scrollbar-highlight-color: $white;
                scrollbar-track-color: $white;
                scrollbar-arrow-color: $black;
                scrollbar-shadow-color: $white;

                // Firefox Scroll Bar Styling
                scrollbar-color: $black $white;

                // Webkit Scroll Bar Styling
                &::-webkit-scrollbar-track {
                    background-color: $white;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 2px;

                    background-color: rgba(0, 0, 0, 0.15);
                }

                &::-webkit-scrollbar-thumb:hover {
                    border-radius: 2px;

                    background-color: $black;
                }

                li {
                    &:hover,
                    &.selected,
                    &.active {
                        color: $black;
                    }

                    &.active,
                    &:hover.active {
                        &:before {
                            border: solid $black;
                        }
                    }
                }
            }
        }

        &__group {
            label {
                color: $black;

                border: 2px solid transparent;

                background-color: $grey-silver;

                &.has-focus {
                    outline: none;

                    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
                }

                &.active,
                &:hover.active {
                    &:before {
                        content: '';

                        border: solid rgba(0, 0, 0, 0.75);
                    }
                }

                &.is-checked {
                    color: $black;

                    background: $white;

                    &.has-focus {
                        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
                    }
                }

                &.is-disabled {
                    color: $grey;

                    background: $grey-dark;
                }
            }
        }

        .invalid-feedback {
            color: $red-utility;
        }

        .form-text {
            color: $grey-baked;
        }

        &:not(.pristine-is-invalid.had-focus) {
            &.had-focus {
                label select,
                select {
                    color: $white-concrete;
                }
            }

            label select,
            select {
                &:not([multiple]),
                &[size='1'] {
                    background-image: var(--chevron-down-small-white);
                }
            }

            .has-value {
                select {
                    color: $white;
                }
            }
        }
    }
}

.btn-custom-toggle {
    $this: '.btn-custom-toggle';
    position: relative;

    display: inline-block;

    width: 4.2rem;
    height: 2.4rem;
    padding: (math.div(2, 42) * 100%);

    color: var(--pat-theme-fore, $black) !important;

    border-radius: 1.2rem;

    background: $grey-gallery;

    cursor: pointer;

    overflow: hidden;

    transition:
        background-color 0.5s $ease-out-slide,
        box-shadow 0.25s $ease-out-slide;

    &:focus {
        outline: none;

        box-shadow: 0 0 3px 2px rgba($black, 0.2);
    }

    &__switch {
        position: absolute;
        top: (math.div(4, 24) * 100%);
        left: (math.div(4, 42) * 100%);
        bottom: (math.div(4, 24) * 100%);
        z-index: 2;

        width: (math.div(16, 42) * 100%);

        border: 1px solid $grey-white;
        border-radius: 100%;

        background: $white;

        box-shadow: 0 2px 4px 0 rgba($black, 0.2);

        pointer-events: none;

        transition:
            left 0.5s $ease-out-slide,
            background-color 0.5s $ease-out-slide,
            border 0.5s $ease-out-slide;
    }

    &__bg-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        width: 1rem;
        height: 1rem;

        transform: translate(-50%, -50%);

        pointer-events: none;

        transition: opacity 0.5s $ease-out-slide;

        &--left {
            left: 30%;

            opacity: 0;
        }

        &--right {
            left: 70%;
        }

        svg {
            position: absolute;
            inset: 0;

            width: 1rem;
            height: 1rem;
        }

        svg,
        svg * {
            fill: currentColor !important;
        }
    }

    &[aria-checked='true'] {
        background: currentColor;

        #{$this}__switch {
            left: ((42 - 24 + 2) * 0.025 * 100%);
        }

        svg,
        svg * {
            fill: $white !important;
        }

        #{$this}__bg-icon {
            &--left {
                opacity: 1;
            }

            &--right {
                opacity: 0;
            }
        }
    }
}

// Add support for click events on body to close open select lists */
body.custom-input-opened {
    cursor: pointer;
}
