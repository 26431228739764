// from modules/navigation/navigation-primary/style.scss
@use 'sass:map';
@use 'sass:math';

html {
    --pat-header-nav-height: 5.6rem;
    --pat-top-nav-items-height: calc(var(--pat-header-nav-height) + var(--pat-utility-bar-height));
}

.skip-to-content-link {
    position: absolute;
    left: 2px;
    z-index: 500;

    height: 3rem;
    padding: 0 $spacer * 2;

    color: $white;
    line-height: 3rem;
    text-decoration: none;

    background: $black;

    transform: translateY(-100px);

    transition: transform 0.3s;

    &:hover {
        color: $white;
    }

    &:focus {
        text-decoration: none;

        border-radius: $border-radius;

        outline: solid medium $blue-utility;
        outline-offset: 1px;

        transform: translateY(2px);
    }
}

.navigation-primary {
    $this: &;

    position: sticky;
    top: calc(var(--pat-header-nav-height) * -1.5);
    left: 0;
    z-index: map.get($z-indexes, 'header');

    width: 100%;
    height: auto;

    // set to -1 to help IntersectionObserver
    // see: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
    margin-top: -1px;
    padding-top: 1px;

    transition:
        top 0.6s $ease-out-soft,
        transform 0.6s $ease-out-soft;

    .scroll-direction-up &,
    body.nav-open & {
        // set to -1 to help IntersectionObserver
        // see: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        top: -1px;
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    .offcanvas-backdrop.show {
        opacity: 0.4;
    }

    &__bar-wrapper {
        position: relative;
        z-index: 10;

        width: 100%;
        height: auto;

        color: $black;

        background: $white;

        transition: box-shadow ease 0.5s;

        button {
            @include focus-visible {
                outline: 0.2rem $blue-utility auto;
            }
        }

        .scroll-direction-up .is-pinned & {
            @include DropShadow(4);
        }
    }

    &__cols {
        position: relative;
        z-index: ($zindex-offcanvas + 1);

        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr auto 1fr;
        gap: $spacer;

        min-height: $spacer * 7;
        padding: 0 var(--bs-gutter-x);

        background: $white;

        @include mq('medium') {
            padding: 0 calc(var(--bs-gutter-x) * 2);
        }

        @include mq('large') {
            justify-content: center;

            padding: 0 calc(var(--bs-gutter-x) * 4);
        }

        @include mq('xlarge') {
            padding: 0 calc(var(--bs-gutter-x) * 5);
        }
    }

    &.is-pinned {
        z-index: map.get($z-indexes, 'sticky');
    }

    &__col {
        display: grid;
        align-content: center;
        align-items: center;
    }

    &__col:first-child,
    &__col + &__col + &__col {
        grid-template-rows: auto;
        grid-template-columns: auto auto auto auto auto;
    }

    &__col + &__col + &__col {
        justify-content: end;
    }

    &__col:first-child {
        justify-content: start;
    }

    &__logo {
        z-index: 1;

        align-self: stretch;

        padding-right: $spacer;
        padding-left: $spacer;

        text-align: center;

        transition: opacity 0.3s $ease-out-soft;

        svg:not(.colored) * {
            fill: $black !important;
        }

        svg {
            width: 12rem;
            height: 2.2rem;
        }
    }

    &__logo-pro svg {
        width: 2.8rem * math.div(152, 35);
        height: 2.8rem;
    }

    &__expanded-nav-items,
    &__nav-items {
        z-index: 2;

        display: none;

        height: 100%;

        @include mq('large') {
            display: flex;
        }

        &:hover {
            #{$this}__expanded-nav-item,
            #{$this}__nav-item {
                &.active {
                    figure {
                        opacity: 0;
                        transform: translateY(-100%);
                    }
                }

                &:hover {
                    figure {
                        opacity: 1;
                        transform: translateY(-50%);

                        &.tile-card {
                            transform: none;
                        }
                    }
                }
            }
        }
    }

    &__expanded-nav-item,
    &__nav-item {
        position: relative;
        top: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        margin: 0;
        padding: 0;

        overflow: hidden;

        a,
        button {
            position: relative;
            z-index: 1;

            display: inline-block;

            height: 100%;

            pointer-events: auto;

            &:hover {
                text-decoration-line: none;
            }
        }

        button {
            @include p-sm;
            @include underline-styles;

            position: relative;
            left: $spacer * 3 * -0.5;

            height: 100%;
            padding: 2px $spacer * 3 * 0.5;

            color: $black;

            will-change: font-weight;

            @include mq('xlarge') {
                @include p;
            }

            &.active {
                @include font-family('medium');
            }

            &:hover {
                @include underline-styles;
                text-decoration-line: underline;
            }
        }
    }

    &__expanded-nav-items {
        position: relative;

        height: 12rem;

        #{$this}__nav-item {
            span {
                color: currentColor;
            }
        }
    }

    &__expanded-nav-items-wrapper {
        position: absolute;
        top: 0;
        left: 0;

        flex: none;

        width: 100%;
        height: 0;

        opacity: 0;

        transition: opacity 0.3s $ease-out-soft;
    }

    &__icon {
        position: relative;
        z-index: 1;

        flex: 0 0 auto;

        width: $spacer * 4;
        height: $spacer * 4;
        margin: 0 var(--bs-gutter-x) 0 0;

        transition: opacity 0.3s $ease-out-soft;

        &:last-child {
            margin-right: 0;
        }

        a {
            @include is-absolute-fs;

            text-decoration: none;

            pointer-events: auto;
        }

        a,
        button {
            &:hover {
                text-decoration: none;

                svg {
                    transform: translate(-50%, -50%) scale(1.08);
                }
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            transition:
                opacity $button-out-time $ease-fade,
                transform $button-out-time $ease-hover;

            &:not(.colored) * {
                fill: $black !important;
            }
        }

        &--cart {
            &_count {
                @include p-xs;
                position: absolute;
                top: -0.8rem;
                right: -0.8rem;

                height: 2.4rem;
                min-width: 2.4rem;

                color: $white;
                line-height: 2.4rem;
                text-align: center;

                border-radius: 2.4rem;

                background-color: $red-brand;

                transition: opacity 0.25s ease;

                &:empty {
                    opacity: 0;

                    pointer-events: none;
                }
            }
        }

        &--login,
        &--hamburger-desktop,
        &--search-desktop {
            display: none;

            @include mq('large') {
                display: block;
            }
        }

        &--hamburger-mobile,
        &--search-mobile {
            @include mq('large') {
                display: none;
            }
        }

        .icon--navigation-primary--hamburger {
            width: $spacer * 2.75;
            height: $spacer * 2.75;
        }

        .icon--navigation-primary--cart {
            width: $spacer * 2;
            height: $spacer * 2;
        }

        .icon--navigation-primary--search {
            width: $spacer * 2;
            height: $spacer * 2;
        }

        .icon--navigation-primary--wishlist {
            width: 1.4rem;
            height: 1.3rem;
        }

        &--close {
            display: none;
        }
    }

    &__icon-state {
        @include is-absolute-fs;

        visibility: hidden;

        &.active {
            visibility: visible;
        }
    }

    // expanded states

    &__expanded {
        &:after {
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            content: '';
            z-index: 9;

            width: 100%;
            height: calc($spacer * 4 + env(safe-area-inset-bottom));

            background-image: linear-gradient(-180deg, rgba($white, 0) 0%, $white 100%);

            transition: opacity 0.5s $ease-out-soft;
        }
    }

    &__expanded-primary-menus-wrapper,
    &__expanded-submenu-wrapper {
        @include is-absolute-fs;
        @include transition(opacity 0.4s $ease-out-soft, transform 0.4s $ease-out-soft);
        display: block;

        height: 100vh;
        height: -webkit-fill-available;
        box-sizing: content-box;

        backface-visibility: hidden;

        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @include mq('large') {
            .navigation-primary__expanded-secondary-menu {
                display: none;
            }
        }

        &,
        .navigation-primary__expanded-secondary-menu {
            padding-top: $spacer * 10;

            @include mq('large') {
                padding-top: 0;
            }
        }

        &.fade:not(.show) {
            transform: translateY(10 * $spacer);
        }
    }

    &__expanded-submenu-wrapper {
        z-index: map.get($z-indexes, 'sticky');

        display: block;

        backface-visibility: visible;

        &.fade:not(.show) {
            z-index: 1;
        }

        &.hide {
            opacity: 0;
            transform: translateY(5 * $spacer);

            &.show {
                @include transition(opacity 0.1s ease, transform 0.1s ease);
            }
        }

        button,
        a {
            @include p-lg;
        }
    }

    &__expanded-primary-menus-wrapper {
        z-index: 2;

        color: $black;

        #{$this}__expanded-section {
            &.is-reset {
                ul {
                    transition: none !important;
                }

                li {
                    transition: none !important;
                }
            }
        }
    }

    &__expanded-bar-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: map.get($z-indexes, 'sticky') + 1;

        display: flex;
        justify-content: space-between;

        width: 100%;
        padding: 0 calc(var(--bs-gutter-x) * 1.5) 0 calc(var(--bs-gutter-x) * 2);

        @include mq('medium') {
            padding: 0 calc(var(--bs-gutter-x) * 2) 0 calc(var(--bs-gutter-x) * 4);
        }

        @include mq('large') {
            padding: 0 $spacer * 10;
        }
    }

    // expanded nav bar left ctas

    &__expanded-bar-left-ctas-wrapper {
        position: absolute;
        top: $spacer * 1;
        left: $spacer * 2;

        display: block;

        @include mq('large') {
            position: relative;
            top: 0;
            left: 0;
        }

        #{$this}__expanded-bar-login-cta-wrapper {
            display: none;

            @include mq('large') {
                display: flex;
            }
        }

        .header-store-location {
            width: 50vw;

            @include mq('large') {
                display: none !important;
            }

            &__inner {
                font-weight: $font-weight-bold;
            }
        }
    }

    &__expanded-bar-back-cta-wrapper {
        @include transition(opacity 0.5s ease, transform 0.5s ease);

        position: absolute;
        top: $spacer * 2;
        left: $spacer;

        display: block;

        visibility: visible;

        opacity: 1;

        @include mq('medium') {
            left: $spacer * 4;
        }

        @include mq('large') {
            visibility: hidden;

            opacity: 0;
        }

        &:not(.show) {
            opacity: 0;
            transform: translateY($spacer * -4);
        }

        .cta-link-back {
            top: 0;

            pointer-events: all;

            figure,
            svg {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    &__expanded-bar-submenu-title-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        width: 100%;
        padding: $spacer * 3 0 $spacer * 7;

        text-align: center;

        background: linear-gradient(
            to bottom,
            rgba($white, 100%),
            rgba($white, 100%) 50%,
            rgba($white, 0%)
        );

        pointer-events: none;

        @include mq('large') {
            display: none;
        }
    }

    &__expanded-bar-submenu-title {
        @include transition(opacity 0.5s, transform 0.5s);
        @include h6;
        display: block;

        &:empty {
            opacity: 0;
            transform: translateY($spacer * -4);
        }
    }

    #{$this}__expanded-bar-lets-chat {
        display: none;

        visibility: hidden;

        opacity: 0;
    }

    &__expanded-bar-login-cta-wrapper {
        position: absolute;
        top: 4rem;
        left: 0;
        z-index: 100;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 3rem;

        color: $white;

        transition:
            opacity 0.4s $ease-out-soft,
            visibility 0.4s;

        @include mq('medium') {
            top: 4.4rem;
        }

        @include mq('large') {
            top: $spacer * 5;
        }

        a {
            color: inherit;
            white-space: nowrap;

            &:not(.btn) {
                color: $black;
            }
        }

        span {
            color: currentColor;
            font-size: 2rem;

            @include mq('medium') {
                font-size: 1.6rem;
            }
        }
    }

    // expanded nav bar right ctas

    &__expanded-bar-right-ctas-wrapper {
        position: absolute;
        top: $spacer * 2;
        right: $spacer * 1;

        display: flex;
        flex-flow: row nowrap;

        @include mq('large') {
            top: $spacer * 5;
            right: $spacer * 10;
        }

        .cta-circle-outlined {
            border-width: 0 !important;
        }

        .icon--cta-circle--search {
            width: $spacer * 3;
            height: $spacer * 3;
        }

        > * {
            margin-right: 0;
            margin-left: calc(var(--bs-gutter-x) * 0.5);

            @include mq('large') {
                position: relative;

                margin-left: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        .modal-close {
            top: 1rem;
            right: calc(var(--bs-gutter-x) * 0.5);
        }
    }

    &__expanded-bar-search-cta,
    &__expanded-bar-lets-chat,
    &__expanded-bar-close-cta {
        z-index: 10;

        &.cta-circle {
            @include ie {
                position: absolute;
            }
        }
    }

    &__expanded-bar-search-cta.cta-circle {
        visibility: hidden;

        @include mq('large') {
            right: calc(var(--bs-gutter-x) * 1);

            visibility: visible;
        }
    }

    &__expanded-bar-lets-chat.cta-link-underline {
        right: 5rem;

        height: 2.75em;

        font-weight: $font-weight-bold;
        line-height: 2.75rem;

        @include mq('medium') {
            top: 0.6rem;
            right: calc(var(--bs-gutter-x) * 0.5);
        }

        span {
            font-size: 1.4rem;

            @include mq('large') {
                font-size: 1.6rem;
            }
        }
    }

    &__expanded-bar-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        width: 100%;
        height: 10rem;

        background: transparent;

        visibility: hidden;

        opacity: 0;

        transition:
            opacity 0.3s $ease-out-soft,
            visibility 0.3s;
    }

    &__expanded-section,
    &__expanded-secondary-menu {
        $y-slide-amount: 20%;

        @include is-absolute-fs;
        z-index: 1;

        height: auto;
        padding: $spacer * 4;
        box-sizing: border-box;

        @include mq('medium') {
            padding: $spacer * 10;
        }

        @include mq('large') {
            min-height: 100vh;
            padding: calc(10vh + var(--bs-gutter-x) * 4) calc(var(--bs-gutter-x) * 4) 12vh;
        }

        [data-bs-toggle] {
            margin-bottom: 0;
            padding-right: $spacer * 2;

            .icon {
                width: $spacer * 2;
            }

            .icon svg {
                top: 50%;
                left: 50%;

                width: 1.1rem;
                height: 0.7rem;

                transform: translate(-50%, -50%);
            }
        }

        // navigation sections

        &--list-sections,
        &--list-support {
            > li {
                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        &--mobile,
        &--mobile-support-column {
            ul {
                transition:
                    opacity 0.4s $ease-out-soft,
                    transform 0.6s $ease-out-slide;
            }

            li {
                flex: 1 0 100%;

                margin-bottom: $spacer;

                @include ie {
                    margin-bottom: 6em;
                }

                a,
                button {
                    &:not(.btn) {
                        display: inline-block;

                        padding: $spacer 0;

                        text-align: start;
                    }
                }
            }
        }

        &--support {
            position: relative;

            display: none;
            flex-flow: column nowrap;
            justify-content: center;

            width: 100%;
            margin-top: 0;
            padding: 10rem 4rem 16rem;

            @include mq('medium') {
                padding: 24rem 16rem 16rem;
            }

            @include mq('large') {
                display: block;
            }

            .navigation-primary__expanded-section--content-wrapper {
                flex-flow: row wrap;
            }
        }

        .mobile-specific-menu {
            @include mq('large') {
                display: none;
            }
        }

        &--mobile {
            flex-flow: row wrap;
            align-content: center;
            align-items: center;

            padding-top: $spacer * 10;

            @include mq('large') {
                display: none;
            }

            > ul,
            > ul li[data-section='shop'] {
                margin-bottom: $spacer * 3;

                a {
                    color: $black;
                }
            }

            > ul li[data-section='shop'] {
                padding-bottom: $spacer * 3;

                &:after {
                    position: absolute;
                    bottom: 0;
                    content: '';

                    display: block;

                    width: 100%;
                    height: 1px;

                    background-color: $grey;
                }
            }

            > ul button,
            > ul .skeleton-element {
                @include p-xl;
                @include underline-styles;

                &:hover {
                    text-decoration-line: underline;
                }
            }

            .icon svg:not(.colored) *:not(.colored) {
                fill: $black;
            }

            .mobile-specific-menu {
                @include mq('large') {
                    display: none;
                }

                h2 {
                    margin: 2% 0;
                }

                a {
                    display: inline-block;

                    margin: 0;

                    color: $black;
                    text-decoration: none;

                    span {
                        display: block;

                        margin: 0;
                    }

                    &.has-icon {
                        span {
                            display: inline-block;
                        }
                    }

                    svg {
                        display: inline-flex;
                        align-self: center;

                        width: 1.4rem;
                        height: 1.4rem;
                        margin-left: 0.4rem;

                        fill: $black;
                    }
                }
            }

            .navigation-primary {
                &__expanded-section {
                    &--list-support {
                        margin-top: 1.7rem;

                        li {
                            margin-bottom: 1.1rem;

                            transform: translateY(2rem);
                        }
                    }
                }
            }

            > * {
                flex: 1 0 100%;
            }
        }

        // section content

        &--content-wrapper {
            position: relative;
            z-index: 5;

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;

            padding-bottom: calc($spacer * 4 + env(safe-area-inset-bottom));

            [data-bs-toggle='collapse'] + div {
                li {
                    margin-bottom: 0;
                }

                a {
                    padding-top: $spacer;
                    padding-bottom: $spacer;
                }
            }

            span,
            a:not(.btn) {
                color: currentColor;
                text-align: left;

                @include mq('medium') {
                    text-align: left;
                }
            }

            a,
            button {
                &:not(.btn) {
                    display: inline-block;

                    margin-bottom: $spacer;
                    padding: $spacer 0;

                    text-align: start;

                    &:hover {
                        text-decoration-line: underline;
                    }

                    &.show-registered,
                    &.show-unregistered {
                        display: none;
                    }
                }
            }

            > * {
                position: relative;
            }

            > li {
                display: block;

                width: 100%;
                max-width: 55rem;
            }

            [data-bs-toggle='collapse'][aria-expanded='true'] {
                .icon--chevron-down {
                    svg {
                        transform: rotate(180deg);
                        transform-origin: center;
                    }
                }
            }
        }

        &--column {
            flex: 1 0 0;

            width: 100%;
            max-width: 38rem;
            padding: 0;

            @include mq('large') {
                flex: 2 0 33.3%;

                padding: 0 4rem;
            }

            span,
            a {
                text-align: left;
            }

            > span {
                @include p-xs;
                display: block;

                margin-bottom: $spacer * 3;

                color: $grey-tundora;
                font-weight: $font-weight-bold;

                transform: translateY(1rem);

                transition:
                    opacity 0.2s $ease-out-soft,
                    transform 0.16s $ease-out-slide;
            }

            > span + ul {
                margin-top: $spacer * 4;

                li {
                    margin-bottom: $spacer;

                    a,
                    button {
                        &:not(.btn) {
                            display: inline-block;

                            padding-top: $spacer;
                            padding-bottom: $spacer;

                            text-align: start;
                        }
                    }
                }
            }

            > ul {
                margin-bottom: 3rem;

                @include mq(large) {
                    margin-bottom: 6rem;
                }

                li {
                    position: relative;
                }
            }
        }

        &--logos {
            position: relative;
            z-index: 1;

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            width: 100%;
            max-width: 81.6rem;
            margin: $spacer * 5 0 0;

            @include mq('medium') {
                padding: 0 2rem;
            }

            @include mq('large') {
                margin: 0 -0.8rem;
                padding: 0;
            }

            li,
            a {
                position: relative;

                display: block !important;

                height: inherit;

                @include mq('large') {
                    width: 14em;
                }
            }

            li {
                margin: 0 0 $spacer * 3;
            }

            figure {
                position: relative;

                width: 100%;
                height: 12em;

                @include mq('large') {
                    width: 14em;
                    height: 10em;
                }

                @include no-hover {
                    .nav-logo-foreground {
                        display: none;
                    }

                    .nav-logo-text {
                        display: inline-block;
                    }

                    .nav-logo-background {
                        opacity: 0.5;
                    }
                }

                img,
                svg {
                    position: relative;

                    height: 100%;
                }

                &:hover {
                    .nav-logo-foreground {
                        display: none;
                    }

                    .nav-logo-text {
                        display: inline-block;
                    }

                    .nav-logo-background {
                        opacity: 0.5;
                    }
                }
            }

            svg {
                width: 10em;
            }

            .svg-half-width {
                width: 50%;
                margin-left: 25%;
            }

            .svg-three-quarter-width {
                width: 70%;
                margin-left: 15%;
            }

            .nav-logo-background {
                position: absolute;
                left: 0;
                z-index: 9;

                width: 100%;

                border-radius: $border-radius;
            }

            .nav-logo-foreground {
                z-index: 10;
            }

            .nav-logo-text {
                position: relative;
                z-index: 10;

                display: none;

                width: 100%;
                height: 100%;

                color: $black;
                font-weight: $font-weight-bold;
                font-size: 1.65rem;
                text-align: center;

                @include no-hover {
                    display: block;
                }

                strong {
                    position: relative;
                    top: 3.5em;
                }

                svg {
                    position: absolute;
                    top: 10%;
                    right: 10%;

                    width: 0.8em;
                    height: auto;
                }
            }
        }

        &--bug-wrapper {
            position: absolute;
            inset: auto 4rem 4rem auto;
            z-index: 10;

            opacity: 0;

            transition: opacity 0.2s $ease-out-soft;
        }
    }

    &__expanded-help-cta {
        z-index: 10;

        flex: 0 0 auto;

        width: auto;
        max-width: none;
        margin-top: 4.9rem;

        opacity: 0;

        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.3s $ease-out-soft;
    }

    &__expanded-logos {
        &--wrapper {
            position: fixed;
            top: auto;
            bottom: 0;
            z-index: 15;

            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            width: 100%;
            height: 7rem;
            margin: 0 calc(var(--bs-gutter-x) * -2);
            padding: 0 calc(var(--bs-gutter-x) * 2);

            background: $white;

            visibility: hidden;

            opacity: 0;

            pointer-events: none;

            transition:
                opacity 0.3s $ease-out-soft,
                visibility 0.3s;

            @include mq('medium') {
                display: none;
            }
        }

        &--logos {
            position: relative;
            top: auto;
            bottom: 0;

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            width: 100%;

            li {
                position: relative;

                flex: 0 1 auto;

                margin: 0 0.8rem;

                a {
                    display: inline-block;
                }
            }

            figure {
                position: relative;

                width: auto;
                height: 2.5rem;

                &.is-stacked {
                    height: 3.2rem;
                }

                img,
                svg {
                    position: relative;

                    height: 100%;
                }
            }
        }
    }

    .is-menu-mobile-only {
        position: relative;

        display: block;

        @include mq('large') {
            display: none;
        }
    }

    .activism-logo-card {
        display: block;

        width: 100%;
        padding-right: min(8rem, 2.5vw);

        @include mq('large') {
            margin-bottom: $spacer * 3;
        }

        a.overflow--item-link:not(:-webkit-any(.text-link-sm, .btn)) {
            width: 100%;
            max-width: 27rem;
            padding-right: 0;
        }

        .tile-card {
            position: relative;

            width: 100%;
            height: 14rem;

            opacity: 1;
            transform: none;

            img,
            svg {
                position: relative;

                height: 100%;

                object-fit: cover;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);
            }

            &:hover {
                .nav-logo-foreground {
                    display: none;
                }

                .nav-logo-text {
                    display: inline-block;
                }

                .nav-logo-background {
                    opacity: 0.5;
                }
            }
        }

        .nav-logo-background {
            position: absolute;
            left: 0;
            z-index: 9;

            width: 100%;

            border-radius: $border-radius;
        }

        .nav-logo-foreground {
            z-index: 10;
        }

        .nav-logo-text {
            position: absolute;
            left: 0;
            z-index: 10;

            display: none;

            width: 100%;
            height: 100%;

            font-weight: $font-weight-medium;
            font-size: 1.65rem;
            text-align: center;

            strong {
                position: relative;
                top: 3.5em;
            }
        }
    }

    .activism-signup-card {
        margin-top: $spacer;

        @include mq('large') {
            margin-top: $spacer * 3;
            padding-left: $spacer * 1.5;
        }

        li {
            max-width: 27rem;
            padding: $spacer * 2;

            color: currentColor;
            text-align: left;

            border: 2px solid currentColor;
            border-radius: $border-radius;

            p:not(.p-xs) {
                @include title-4;

                margin-bottom: $spacer * 2;
            }

            .text-link-sm {
                @include underline-styles;
                @include title-5;

                display: inline-block;

                margin: 0;

                font-weight: $font-weight-medium;

                &:first-of-type {
                    margin-right: $spacer * 2;
                }

                @include mq('large') {
                    margin: $spacer 0 0;
                }
            }
        }
    }

    #offcanvas-nav-activism {
        .level-two,
        .level-three,
        .level-four {
            li {
                margin-bottom: 0;
                padding-top: $spacer * 3;
            }
        }
    }
}

.user-registered {
    .navigation-primary__expanded-section--mobile li a.show-registered {
        display: inline-block;
    }
}

.user-unregistered {
    .navigation-primary__expanded-section--mobile li a.show-unregistered {
        display: inline-block;
    }
}

.navigation-simplified {
    position: relative;

    padding: 1rem 0;

    @include mq('large') {
        padding: 3.5rem 0;

        text-align: center;
    }

    .user-pro & {
        .show-pro {
            display: inline;
        }
    }

    .user-non-pro & {
        .show-non-pro {
            display: inline;
        }
    }

    &__logo {
        position: relative;
        top: 0;
        z-index: 1;

        flex: 0 0 auto;

        width: 12.5rem;
        height: 3.5rem;

        text-align: center;

        transition: opacity 0.3s $ease-out-soft;

        @include mq('medium') {
            width: 16rem;
            height: 4rem;
        }

        @include mq('large') {
            width: 20rem;
            height: 5rem;
        }

        figure {
            display: inline-block;
        }

        svg:not(.colored) * {
            fill: $black !important;
        }

        svg {
            width: 10.7rem;
            height: 3.5rem;

            @include mq('medium') {
                width: 12.9rem;
                height: 4rem;
            }

            @include mq('large') {
                width: 16.1rem;
                height: 5rem;
            }
        }
    }

    &__logo-pro svg {
        width: 12.5rem;
        height: 3.5rem;

        @include mq('medium') {
            width: 16rem;
            height: 4rem;
        }

        @include mq('large') {
            width: 20rem;
            height: 5rem;
        }
    }

    &__icon {
        position: relative;
        z-index: 1;

        flex: 0 0 auto;

        width: $spacer * 4;
        height: $spacer * 4;
        margin: 0 calc(var(--bs-gutter-x) * 0.5);

        transition: opacity 0.3s $ease-out-soft;

        @include mq('large') {
            margin-left: 0;
        }

        a {
            @include is-absolute-fs;

            width: auto;

            text-decoration: none;

            pointer-events: auto;

            &:hover {
                text-decoration: none;

                svg {
                    transform: scale(1.08);
                }
            }
        }

        svg {
            transition:
                opacity $button-out-time $ease-fade,
                transform $button-out-time $ease-hover;

            &:not(.colored) * {
                fill: $black !important;
            }
        }

        &--cart {
            margin-right: -0.6rem;

            @include mq('large') {
                display: none;
            }
        }

        &--btc,
        &--browsing {
            display: none;

            height: 2.5rem;

            text-align: left;

            @include mq('large') {
                display: block;
            }

            a:hover {
                svg {
                    transform: none;
                }

                span {
                    @include underline-styles;
                    text-decoration-line: underline;
                }
            }

            figure {
                display: inline;
            }

            span {
                @include h8;
                margin-left: 0.5rem;

                line-height: 1.7rem;
                vertical-align: middle;
            }
        }

        &--btc {
            width: calc(50% - 100px); // calc to get width minus Logo - to prevent overlapping logo
        }

        &--browsing {
            width: calc(50% - 100px); // calc to get width minus Logo - to prevent overlapping logo
        }
    }

    &__links {
        position: absolute;
        inset: 0;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mq('large') {
            justify-content: space-between;
        }

        &--checkout {
            justify-content: flex-end;
            align-items: center;

            @include mq('large') {
                justify-content: flex-start;
            }
        }

        &--checkout-unregistered {
            flex-direction: row-reverse;
            justify-content: flex-start;

            @include mq('large') {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

.account-login-row {
    display: none;
}

.drawer-top + .account-login-row {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;

    width: 100%;

    .header-store-enabled & {
        @include mq('medium') {
            z-index: (map.get($z-indexes, 'sticky') + 2);
        }
    }
}

.account-login-container,
%account-login-container {
    position: relative;
    z-index: map.get($z-indexes, 'sticky');

    display: none;

    width: 100%;
    margin: 0 4rem;

    @include mq('medium') {
        display: block;
    }

    @include mq('large') {
        margin: 0 calc(var(--bs-gutter-x) * 4);
    }

    .account-login-link {
        color: $white;
        line-height: 4.8rem;
    }
}

.account-wrapper {
    position: absolute;
    top: 0;
    right: 0;

    transition: transform 0.3s 0.6s $ease-out-soft;
}

.nav-red-text-activated {
    .offcanvas--utility-nav,
    .offcanvas--main-nav {
        button[aria-owns*='web-specials'],
        button[aria-owns*='web-specials'] span {
            color: $red-utility;
        }

        [aria-owns*='web-specials'] {
            button[aria-owns*='web-specials'],
            button[aria-owns*='web-specials'] span {
                color: $black;
            }
        }
    }
}

.offcanvas--main-nav,
.offcanvas--utility-nav {
    .is-fixed & {
        &.show {
            display: none;
        }
    }
}

.offcanvas--main-nav {
    @include transition(transform $offcanvas-transition-duration ease-in-out, padding ease 0.25s);

    width: auto !important;
    max-width: 90vw;
    padding-top: $spacer * 13;

    color: $black;

    border: none !important;

    .is-pinned & {
        padding-top: $spacer * 7;
    }

    .offcanvas-header {
        justify-content: end;

        padding: $spacer * 2;
    }

    a:not(.btn) {
        color: $black;
    }

    .btn-close {
        width: $spacer * 2;
        height: $spacer * 2;
        margin: 0;

        background: none;

        opacity: 1;

        svg {
            width: $spacer * 2;
            height: $spacer * 2;

            vertical-align: top;
        }
    }

    .offcanvas-body {
        position: relative;
        left: calc(($spacer * 3 * -0.5) + (var(--bs-gutter-x) * 0.5));

        padding: $spacer * 3 0 $spacer * 6 $spacer * 6;

        overflow-x: hidden;

        @include mq('medium') {
            padding-left: calc(var(--bs-gutter-x) * 2);
        }

        @include mq('large') {
            padding-left: calc(var(--bs-gutter-x) * 4);
        }

        @include mq('xlarge') {
            padding-left: calc(var(--bs-gutter-x) * 5);
        }

        > .row {
            flex-wrap: nowrap;

            width: auto;

            .col {
                padding: 0;
            }
        }

        ul {
            padding-right: min($spacer * 10, 2.5vw);

            list-style-type: none;
        }

        li {
            width: clamp(10vw, 15vw, 240px);
            margin-bottom: $spacer * 2;
        }

        .level-one li {
            a:not(.text-link-sm, .btn),
            button:not(.text-link-sm, .btn) {
                @include p-lg;
            }
        }

        .level-two,
        .level-three,
        .level-four {
            li {
                padding-top: 0.6rem; // adding 6px so height equals 30px-- the height of L1 menu items
            }
        }

        .collapse,
        .collapsing {
            transition: none;
        }

        a:not(.text-link-sm, .btn),
        button:not(.text-link-sm, .btn) {
            @include p;
            @include underline-styles;
            display: block;

            width: 100%;
            padding: 0 $spacer * 3 * 0.5;

            text-align: start;

            &:hover {
                text-decoration-line: underline;
            }
        }

        button.active {
            // account for regular -> bold
            margin-right: -0.5em;

            font-weight: $font-weight-bold !important;
        }
    }

    .level-one .shop-menu-additional-links {
        margin-top: $spacer * 3;

        li {
            margin-bottom: $spacer;

            a:not(.text-link-sm, .btn) {
                &,
                span {
                    @include p-sm;
                }
            }
        }
    }
}

.offcanvas--utility-nav {
    @include transition(
        transform $offcanvas-transition-duration ease-in-out,
        opacity $offcanvas-transition-duration ease
    );

    z-index: map.get($z-indexes, 'modal') * 4;

    width: 100%;
    height: 100% !important;

    border: none !important;

    opacity: 0;

    &.showing,
    &.show:not(.hiding) {
        opacity: 1;
    }

    .is-menu-mobile-only--additional-links {
        margin-top: $spacer * 3;
        padding-top: $spacer * 3.5;

        &:before {
            position: absolute;
            top: 0;
            content: '';

            display: block;

            width: 100%;
            height: 1px;

            background-color: $grey;
        }

        li {
            margin-bottom: $spacer * 2.5;

            &:last-child {
                margin-bottom: 0;
            }

            a:not(.text-link-sm, .btn) {
                padding-top: $spacer * 0.5;
                padding-bottom: $spacer * 0.5;

                &,
                span {
                    @include p-lg;
                    color: $black;
                }
            }
        }
    }

    .locale-selector-button {
        @include h10;

        display: flex;
        flex-flow: row;
        align-items: center;

        margin: $spacer * 3 0 0 0;

        @include mq('medium') {
            margin: $spacer * 5 0;
        }

        .icon__flag {
            position: static;

            margin: 0 $spacer;

            img {
                width: $spacer * 3;
                height: $spacer * 3;
            }
        }

        p {
            @include h10;
            display: inline-block;
        }
    }
}

@import './../plugin/instorepickup/header/store-location-widget';
