// shared modal styles
.modal {
    transition: opacity 0.3s $ease-out-soft;
    -webkit-overflow-scrolling: touch;

    &.is-open {
        display: block;

        overflow: hidden auto;

        animation: modal-fade-in 0.3s $ease-out-soft;
    }

    &.is-top {
        .modal-dialog {
            &:before {
                opacity: 0;
            }
        }
    }

    &.is-bottom {
        .modal-dialog {
            &:after {
                opacity: 0;
            }
        }
    }

    &-close {
        position: fixed;
        top: $spacer * 2;
        right: $spacer * 2;
        z-index: 11;

        pointer-events: auto;

        & & {
            position: static;
        }

        svg {
            top: 10px;
            left: auto;
            right: 10px;

            transform: none;
        }
    }

    &__content {
        text-align: center;

        h2 {
            margin: 0 auto 8rem;

            @include mq('medium', 'max') {
                margin: 0 auto 6rem;
            }
        }
    }

    &.show-shadow {
        .modal-content {
            @include DropShadow(4);
        }
    }

    &__body {
        margin: 0 auto;

        text-align: center;
    }

    .modal-dialog {
        position: relative;

        width: 100%;
        max-width: none;
        min-height: 100vh;
        margin: 0;

        color: $white;

        background-color: $black;

        transform: none !important;

        pointer-events: none;

        // Solves for modals being behind iOS Safari bottom bar
        // https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports/
        @supports (height: 100dvh) {
            min-height: 100dvh;
        }

        @at-root .themed.is-light#{&} {
            color: $black;

            background-color: $white;
        }
    }

    &__copy {
        margin-bottom: 3.5rem;
    }

    .modal-content {
        z-index: 2;

        border: 0;

        background-color: $black;

        [data-cta-close-x]:not(.cta-link),
        [data-bs-dismiss]:not(.cta-link, .btn-outlined) {
            color: $white;

            border-color: $black;

            background-color: $black;

            svg,
            svg * {
                fill: $white;
            }

            &.cta-circle-outlined {
                border-color: $white;

                background-color: transparent;
            }

            &:hover {
                transform: none;
            }
        }

        @at-root .themed.is-light#{&},
            .dark-theme & {
            border-color: $white;

            background-color: $white;

            [data-cta-close-x]:not(.cta-link) {
                color: $black;

                border-color: $white;

                background-color: $white;

                svg,
                svg * {
                    fill: $black;
                }

                &.cta-circle-outlined {
                    border-color: $black;

                    background-color: transparent;
                }
            }
        }
    }

    .modal-body {
        z-index: 2;

        display: flex;
        align-items: center;

        width: 100%;
        min-height: 100vh; // Must be min-height to allow scrolling
        padding: 0;

        // ELEMENTS

        .row {
            margin-right: -25px;
            margin-left: -25px;
        }

        [class*='col-'],
        .col {
            padding-right: 25px;
            padding-left: 25px;
        }

        .btn {
            width: 100%;

            @include mq('medium') {
                width: auto;
            }
        }
    }

    &__full,
    &__floating,
    &__content,
    &__bottom,
    &__top,
    &__video {
        position: fixed !important;
        top: 0;
        left: 0;
        z-index: $zindex-modal;

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        .modal-close,
        .modal__close {
            position: absolute;
            top: 0;
            right: 0;

            @include mq('medium', 'max') {
                top: 0;
                right: 0;
            }
        }

        /* Unset */
        .modal-dialog {
            background-color: transparent !important;
        }

        .modal-content {
            position: absolute;
            left: 0;

            width: 100%;
        }

        .modal-body {
            width: unset;
            max-width: 100%;
            margin: 0 auto;

            text-align: center;

            *:not(.row) {
                max-width: 100%;
            }

            .slim {
                width: 70%;
                margin: 0 auto;

                text-align: left;

                ul,
                a {
                    display: inline-block;
                }
            }

            .cta-circle-light.cta-circle-outlined {
                border: 4px solid $white !important;
            }
        }
    }

    &__full,
    &__floating,
    &__content,
    &__video {
        .modal-content {
            top: 0;

            height: 100%;

            border-radius: $border-radius;
        }
    }

    &__full:not(.modal__content),
    &__floating {
        inset: auto 0 0 auto;

        height: calc(100% - 4rem);

        overflow-y: hidden !important;

        .modal-dialog,
        .modal-content {
            height: calc(100vh - 4rem);
            min-height: calc(100vh - 4rem);
        }

        .modal-content {
            padding-top: 6rem;

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            overflow: hidden;

            @include mq('large', 'min') {
                padding-top: 8rem;
            }
        }

        .modal-body {
            flex: 0 0 auto;
            align-items: flex-start;

            height: auto;
            min-height: auto;
            max-height: calc(100vh - 8rem);
            margin: auto;
            padding: 0 1.2rem 4rem;

            overflow: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;

            @include mq('large', 'min') {
                padding: 0 8rem 4rem;
            }
        }

        .modal__close {
            backface-visibility: hidden;
        }

        @media (orientation: $landscape) {
            width: calc(100% - 8rem);
            height: 100%;
            max-width: 140rem;

            @at-root .modal__full--no-max-width:not(.modal__content),
                .modal__floating--no-max-width {
                max-width: none;
            }

            .modal-dialog,
            .modal-content {
                height: 100vh;
                min-height: 100vh;
            }

            .modal-body {
                max-height: 100%;
            }

            .modal-content {
                border-top-right-radius: 0;
                border-bottom-left-radius: 8px;
            }
        }
    }

    &__full:not(.modal__content) {
        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.7s $ease-out-soft;

        .modal__close {
            top: calc(var(--bs-gutter-x) * 0.5);
            right: calc(var(--bs-gutter-x) * 0.5);

            @include mq('large', 'min') {
                top: var(--bs-gutter-x);
                right: var(--bs-gutter-x);
            }

            @media (orientation: $landscape) {
                left: calc(var(--bs-gutter-x) * 0.5);
                right: auto;

                @include mq('large', 'min') {
                    left: var(--bs-gutter-x);
                }
            }
        }

        &.fade:not(.show) {
            transform: translateY(120vh);

            @media (orientation: $landscape) {
                transform: translateX(120vw);
            }
        }
    }

    &__video {
        .modal-body {
            width: 100%;

            video {
                width: 100%;
                height: auto;
                margin: 0 auto;

                border: 0;

                &:focus {
                    outline: none;
                }
            }
        }

        // Add Stroke to White Close Button Fill so it stands out over playing video
        .cta-circle.cta-circle-outlined.cta-circle-light svg * {
            stroke: rgba(0, 0, 0, 0.25);
            stroke-width: 1px;
        }
    }

    &__top {
        .modal-content {
            top: 0;

            height: 50%;
        }
    }

    &__bottom {
        .modal-content {
            bottom: 0;

            height: auto;
        }

        .modal-body {
            height: auto;
            min-height: auto;
        }

        &__left,
        &__right {
            .modal-content {
                left: 0;
                bottom: 2rem;
                right: 0;

                width: 90%;
                max-width: 40rem;
                margin: 0 auto;
                padding: 3rem 2.5rem;

                @include mq('medium') {
                    bottom: 5rem;

                    width: 40%;
                    margin: unset;
                }
            }
        }

        &__left .modal-content {
            left: 0;

            @include mq('medium') {
                left: 5rem;
                right: unset;
            }
        }

        &__right .modal-content {
            right: 0;

            @include mq('medium') {
                left: unset;
                right: 5rem;
            }
        }
    }

    &__floating {
        .modal-content {
            top: 0;
            bottom: 0;
        }

        .modal__close,
        .modal-close {
            top: unset;
            left: calc(50% - 22px);
            bottom: 4rem;
            right: unset;

            border: 4px solid $white !important;
        }
    }

    &__skinny {
        &.modal.modal__content {
            .modal-content {
                max-width: 43rem;
            }
        }
    }
}

body.is-modal-open {
    overflow: hidden;

    .hero-main__caret-wrapper {
        display: none !important;
    }

    [data-custom-cursor] {
        z-index: $zindex-modal + 1;
    }

    .account-login-container {
        z-index: 1;
    }
}

.dark-theme {
    .modal {
        .modal-content {
            color: $black;

            background-color: $white;

            .btn.btn-light,
            .btn.btn-light:focus,
            .btn.btn-light:active {
                --bs-btn-color: #{$white};
                --bs-btn-bg: #{$black};
            }

            .btn.btn-outline.btn-light,
            .btn.btn-outline.btn-light:focus,
            .btn.btn-outline.btn-light:active {
                --bs-btn-bg: #{$black};
            }
        }

        .cta-circle.cta-circle-outlined svg *,
        .dark-theme .cta-circle.cta-circle-outlined.disabled svg * {
            fill: $black !important;
        }

        &__floating {
            .modal-content {
                top: 0;

                height: 100%;
            }

            .modal__close,
            .modal-close {
                border: 4px solid $black !important;
            }
        }
    }
}

.modal-sticky-header {
    position: relative;
    z-index: 10;

    display: block;

    width: auto;
    max-width: calc(80% - 10rem);
    margin: 0 auto;

    text-align: center;

    opacity: 0;

    transition: opacity 0.5s ease;

    h6 {
        display: block;

        max-width: 100%;

        font-size: 1.4rem;
        line-height: 6rem;
        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: hidden;

        @include mq('large', 'min') {
            font-size: 1.8rem;
            line-height: 8rem;
        }
    }

    &--show {
        opacity: 1;
    }
}

// modal animation

@keyframes modal-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
